export default {
  getCustomFieldsForModule: (state) => (moduleName) => {
    if (state.subscriptionLoaded && state.subscription) {
      const module = state
        .subscription
        .modules
        ?.find(
          ({ label }) => [
            moduleName,
            `module.name.${moduleName}`,
          ].includes(label),
        );

      if (module) {
        return module.fields;
      }
    }

    return {};
  },
  subscriptionIsStekCertified(state) {
    return Boolean(state.subscription.company.stek_certificate)
      && ['professional', 'enterprise']
        .includes(state.subscription.subscription_package.toLowerCase());
  },
};
