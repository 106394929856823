import initialState from './state';

export default {
  TEMP_DATA_SUCCESS(state, data) {
    state.temporarySignedData = data.data;
    state.currentInstallationUuid = data.instUuid;
    state.signedAt = Date.now();
  },
  CHECK_RESET(state) {
    if (state.signedAt && (Date.now() - state.signedAt) > 1.8e6) {
      state = initialState;
    }
  },
};
