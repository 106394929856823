import qs from 'qs';
import { axiosInstance } from '../../boot/axios';

export default {
  async requestTempInstallationDetailData({ commit }, request) {
    // eslint-disable-next-line
    try {
      // Return promise values back to component
      const response = await axiosInstance.post(
        request.endpoint,
        qs.stringify({ object_serial_number: request.serial }),
      );

      const data = {
        data: response.data.data,
        instUuid: request.instUuid,
      };

      // Succeeded -> mutate state
      commit('TEMP_DATA_SUCCESS', data);

      // resolve
      return Promise.resolve();
    } catch (error) {
      const data = {
        data: null,
        instUuid: request.instUuid,
      };

      commit('TEMP_DATA_SUCCESS', data);

      return Promise.reject(error);
    }
  },
  checkReset({ commit }) {
    commit('CHECK_RESET');
  },
};
