import _ from 'lodash';
import { useStore } from 'vuex';

export function useStrings() {
  const store = useStore();
  const stringsStore = store.state.stringsStore.strings;

  return {
    getString(path, fallback = path) {
      return _.get(stringsStore, path, fallback);
    },
  };
}

export default {
  methods: {
    setStore(store) {
      this.$store = store;
    },
    getString(path, fallback = path) {
      return _.get(this.$store.state.stringsStore.strings, path, fallback);
    },

    getPluralizedString(path, amount, fallback = path) {
      /** @type String */
      const string = this.getString(path, fallback);

      if (string.includes('|')) {
        const parts = string.split('|');

        return amount === 1 ? parts[0] : parts[1];
      }

      return string;
    },

    // stringReplace(path, [%date%, now()], [%time%, now()], ...)
    stringReplace(data, path, ...args) {
      if (data) {
        let string = this.getString(path);

        // Args contains the replacement string and the string to be replaced
        args.forEach(val => {
          if (val[1]) {
            if (val[0][0] === ':' && typeof val[1] === 'string') {
              // capitalize translation based on if first letter in key is capitalized or not
              // e.g. `:Noun` should result in `Installation`, and `:noun` in `installation`
              if (val[0][1].toUpperCase() === val[0][1]) {
                string = string.replace(val[0], val[1][0].toUpperCase() + val[1].slice(1));
              } else if (val[0][1].toLowerCase() === val[0][1]) {
                string = string.replace(val[0], val[1][0].toLowerCase() + val[1].slice(1));
              } else {
                // technically should never reach this
                string = string.replace(val[0], val[1]);
              }
            } else {
              string = string.replace(val[0], val[1]);
            }
          }
        });

        return string;
      }
      return '';
    },
  },
};
