import { useTranslator } from 'src/mixins/translator';
import { useNotification } from 'src/mixins/notifications';
import EventBus from '../event-bus';

const { translate } = useTranslator();
const { notification } = useNotification();

function handleError(error) {
  // check if error data exists
  if (error?.response?.data) {
    const { data } = error.response;

    /** @todo instead of if/elseif/else checks, should assert the data structure; best used in combination with TypeScript */

    if ('errors' in data && Array.isArray(data.errors)) {
      // data contains an error message and multiple error strings
      const { errors, message } = data;

      notification(
        translate(message),
        'negative',
        {
          caption: errors.map(translate).join(', '),
        },
      );
    } else if ('errors' in data && typeof data.errors === 'object') {
      // data contains an error message and attributed error strings
      const { errors } = data;

      for (const property in errors) {
        // and if there is a property in errors
        if (Object.prototype.hasOwnProperty.call(errors, property)) {
          // if property
          if (property) {
            // store the different error messages for this property
            const errorMessages = error.response.data.errors[property];
            // iterate through the error messages
            errorMessages.forEach((message) => {
              // show popup to notify user
              notification(message, 'negative');
            });
          }

          // @todo check errors on assignment installations

          // // if property is installations
          // if (property === 'installations') {
          //   error.response.data.fields.installations.forEach(inst => {
          //     const subFields = inst;
          //     if (subFields) {
          //       for (const subproperty in subFields) {
          //         if (
          //           Object.prototype.hasOwnProperty.call(
          //             subFields,
          //             subproperty,
          //           )
          //         ) {
          //           // get the string for this property in the database
          //           fieldString = this.getFieldString(subproperty);
          //           // get the error message for the code of this property
          //           errorString = this.getFieldErrorString(
          //             subFields[subproperty].code,
          //           );
          //         }
          //       }
          //     }
          //   });
          // }
        }
      }
    } else if ('error' in data && typeof data.error === 'string') {
      // data contains a single error string
      notification(data.error, 'negative');
    } else if ('message' in data && typeof data.message === 'string') {
      // data contains a single message string
      let message = translate(data.message);

      if ('params' in data) {
        if (data.message === 'exceptions.assignment.invalid_interval_unit') {
          message = message
            .replace(
              ':interval_type',
              this.getPluralizedString(data.params.interval_type, data.params.interval_unit),
            )
            .replace(
              ':interval_unit',
              data.params.interval_unit,
            );
        }
      }

      notification(message, 'negative');
    } else {
      // data does not contain an error message, show generic error message
      notification(translate('error.general'), 'negative');
    }
  }
}

// components using composition API
export function useErrorHandle() {
  return {
    handleError(error) {
      return handleError(error);
    },
  };
}

// components using options API
export default {
  data() {
    return {
      custom_fields: [],
    };
  },

  methods: {
    handleError(error) {
      return handleError(error);
    },
  },

  mounted() {
    // get custom fields
    EventBus.$on('custom-fields', (custom_fields) => {
      this.custom_fields = custom_fields;
    });
  },

  unmounted() {
    // get custom fields
    EventBus.$off('custom-fields');
  },
};
