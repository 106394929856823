import _cloneDeep from 'lodash.clonedeep';
import { useStore } from 'vuex';

function subscriptionModules(moduleLabel, store) {
  if (store.state?.subscriptionStore?.subscription?.modules) {
    const subscriptionModules = _cloneDeep(store.state?.subscriptionStore?.subscription.modules);
    return moduleLabel !== '' && subscriptionModules.filter(module => module.label === moduleLabel).length > 0;
  }
  return false;
}

function userRights(moduleRight, store) {
  const userRights = _cloneDeep(store.state.meStore.me.rights);
  return (moduleRight !== '' && userRights.filter(right => right === moduleRight).length > 0);
}

function userIsRelation(store) {
  const groups = store.state?.authStore?.user?.groups.map(group => group.name);
  return groups.includes('Relations');
}

// components using composition API
export function useModuleRights() {
  const store = useStore();

  return {
    subscriptionModules(moduleLabel) {
      return subscriptionModules(moduleLabel, store);
    },
    userRights(moduleRight) {
      return userRights(moduleRight, store);
    },
    userIsRelation() {
      return userIsRelation(store);
    },
  };
}

// components using options API
export default {
  methods: {
    subscriptionModules(moduleLabel) {
      return subscriptionModules(moduleLabel, this.$store);
    },
    userRights(moduleRight) {
      return userRights(moduleRight, this.$store);
    },
    userIsRelation() {
      return userIsRelation(this.$store);
    },
  },
};
