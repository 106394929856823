import { boot } from 'quasar/wrappers';
import { defineAsyncComponent } from 'vue';
import Vue3TouchEvents from 'vue3-touch-events';

// Components that are registered for global use within the app.
import StickyButtons from '../components/buttons/StickyButtons.vue';
import TextField from '../components/inputs/TextField.vue';
import Autocomplete from '../components/inputs/Autocomplete.vue';

export default boot(({ app }) => {
  app.component('StickyButtons', StickyButtons);
  app.component('TextField', TextField);
  app.component('AsyncAvatar', defineAsyncComponent(() => import('../components/AsyncAvatar.vue')));
  app.component('Autocomplete', Autocomplete);
  app.component('ClBanner', defineAsyncComponent(() => import('../components/banner/ClBanner.vue')));
  app.component('ClDatePicker', defineAsyncComponent(() => import('../components/inputs/DatePicker.vue')));
  app.component('ClTable', defineAsyncComponent(() => import('../components/table/ClTable.vue')));
  app.component('ClStepper', defineAsyncComponent(() => import('../components/stepper/Stepper.vue')));
  app.component('ClDebug', defineAsyncComponent(() => import('../components/dev/Debug.vue')));
  app.component('ClConfirm', defineAsyncComponent(() => import('../components/dialog/Confirm.vue')));

  app.component('CustomFields', defineAsyncComponent(() => import('../components/inputs/CustomFields.vue')));
  app.component('Time', defineAsyncComponent(() => import('../components/inputs/Time.vue')));

  app.component('Chart', defineAsyncComponent(() => import('../components/chart/Chart.vue')));
  app.component('BarChart', defineAsyncComponent(() => import('../components/chart/BarChart.vue')));
  app.component('StackedBarChart', defineAsyncComponent(() => import('../components/chart/StackedBarChart.vue')));

  app.use(Vue3TouchEvents);
});
