import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { boot } from 'quasar/wrappers';
import { api } from './axios';

const wsConfig = {
  port: +(process.env.VUE_APP_WS_PORT ?? 6001),
  key: (process.env.VUE_APP_WS_KEY ?? 'local'),
};

export default boot(({ store }) => {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    Pusher,
    key: 'local',
    authEndpoint: `${api.protocol}://${api.url}/broadcasting/auth`,
    wsHost: api.url,
    wsPort: wsConfig.port,
    wssPort: wsConfig.port,
    forceTLS: true,
    enabledTransports: ['ws', 'wss'],
    cluster: 'mt1',
    auth: {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.state.authStore.token.token}`,
      },
    },
    wsPath: '/ws',
  });
});
