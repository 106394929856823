import localforage from 'localforage';

export default {
  data() {
    return {
      isOnline: this.$store.state.connectionStore.onlineState,
      getBaseUrl: this.$axios.defaults.baseURL,
      appVersion: process.env.VUE_APP_VERSION.substring(0, 16),
      currentCacheName: `CLIMATOOLS-STATIC-REQUESTS-${process.env.VUE_APP_VERSION.substring(0, 16)}`,
      localforageInstancePWA: localforage.createInstance({ name: 'pwadb' }),
    };
  },
};
