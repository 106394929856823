import { debounce } from 'lodash';
import { axiosInstance } from '../../boot/axios';

const generateBaseEndpoint = (assignment, installation, checklist) => [
  'assignments',
  assignment,
  'installations',
  installation,
  'checklists',
  checklist,
].join('/');

export default {
  addRowToGroupedFields({ commit, dispatch }, { assignment, installation, checklist, category, itemIndex, group, hasCalculatedFields }) {
    commit(
      'ADD_ROW_TO_GROUPED_FIELDS',
      { assignment, installation, checklist, category, itemIndex, group },
    );

    if (hasCalculatedFields) {
      dispatch(
        'getCalculatedValuesForChecklist',
        { assignment, installation, checklist, category },
      );
    }
  },
  addSelectedChecklistCategory({ commit, dispatch }, { assignment, installation, categoryName }) {
    const endpoint = `assignments/${assignment}/installations/${installation}/checklists/categories`;

    return axiosInstance
      .post(endpoint, { name: categoryName })
      .then((res) => {
        const checklist = res.data.data;

        commit('ADD_CHECKLIST_CATEGORY', {
          assignment,
          installation,
          checklist,
        });

        dispatch('getChecklistCategories', {
          assignment,
          installation,
          checklist: checklist.id,
        })
          .then((categories) => {
            categories.forEach((category) => {
              dispatch('getChecklistCategoryFields', {
                assignment,
                installation,
                checklist: checklist.id,
                category: category.id,
              });

              dispatch('getChecklistCategoryValues', {
                assignment,
                installation,
                checklist: checklist.id,
                category: category.id,
              });
            });
          });

        return checklist;
      });
  },
  appendNewEquipmentOption(
    { commit, state },
    { newOption },
  ) {
    const fieldNames = [
      'used_tool',
      'tools',
    ];

    if (process.env.DEV) {
      console.time('appendNewEquipmentOption');
    }

    Object.entries(state.checklistFields).forEach(({ 0: assignmentId, 1: installations }) => {
      Object.entries(installations).forEach(({ 0: installationId, 1: checklists }) => {
        Object.entries(checklists).forEach(({ 0: checklistId, 1: categories }) => {
          Object.entries(categories).forEach(({ 0: categoryId, 1: items }) => {
            items.forEach((item, itemIndex) => {
              if (
                item.type === 'field'
                && item.data.type.name === 'custom'
                && fieldNames.includes(item.data.name)
              ) {
                commit('APPEND_OPTION_TO_FIELD', {
                  assignment: assignmentId,
                  installation: installationId,
                  checklist: checklistId,
                  category: categoryId,
                  group: null,
                  itemIndex,
                  rowIndex: -1,
                  newOption,
                });
              } else if (item.type === 'group') {
                item.data.fields.forEach((field, fieldIndex) => {
                  if (field.type.name === 'custom' && fieldNames.includes(field.name)) {
                    commit('APPEND_OPTION_TO_FIELD', {
                      assignment: assignmentId,
                      installation: installationId,
                      checklist: checklistId,
                      category: categoryId,
                      group: item.data.name,
                      itemIndex,
                      rowIndex: fieldIndex,
                      newOption,
                    });
                  }
                });
              }
            });
          });
        });
      });
    });

    if (process.env.DEV) {
      console.timeEnd('appendNewEquipmentOption');
    }
  },
  appendOptionToField(
    { commit },
    { assignment, installation, checklist, category, group, itemIndex, rowIndex, newOption },
  ) {
    commit('APPEND_OPTION_TO_FIELD', { assignment, installation, checklist, category, group, itemIndex, rowIndex, newOption });
  },
  clearAssignments({ commit }) {
    commit('CLEAR_ASSIGNMENTS');
  },
  categoryIsDirty({ state }, { assignment, installation, checklist, category }) {
    return state
      .checklistCategories
      ?.[assignment]
      ?.[installation]
      ?.[checklist]
      ?.find(({ id }) => id === category).is_dirty ?? false;
  },
  dirtyCategories({ state }) {
    const dirtyCategories = [];

    Object.entries(state.checklistCategories).forEach(({ 0: assignmentId, 1: installations }) => {
      Object.entries(installations).forEach(({ 0: installationId, 1: checklists }) => {
        Object.entries(checklists).forEach(({ 0: checklistId, 1: categories }) => {
          categories.forEach((category) => {
            if (category.is_dirty) {
              dirtyCategories.push({ assignmentId, installationId, checklistId, category });
            }
          });
        });
      });
    });

    return dirtyCategories;
  },
  getCalculatedValuesForChecklist: debounce(({ commit, state }, { assignment, installation, checklist, group }) => {
    const baseEndpoint = generateBaseEndpoint(
      assignment,
      installation,
      checklist,
    );

    return axiosInstance.post(
      `${baseEndpoint}/calculate`,
      { fields: state.checklistValues[assignment][installation][checklist] },
    ).then((res) => {
      commit('SET_CALCULATED_CHECKLIST_FIELDS', {
        values: res.data.data,
        assignment,
        installation,
        checklist,
        group,
      });
    });
  }, 300),
  getCalculatedValuesForChecklistCategory: debounce(({ commit, state }, { assignment, installation, checklist, category, group }) => {
    const baseEndpoint = generateBaseEndpoint(
      assignment,
      installation,
      checklist,
    );

    const fields = state.checklistValues[assignment][installation][checklist][category];
    const fieldValues = {};

    Object.entries(fields).forEach(({ 0: field, 1: values }) => {
      fieldValues[field] = values.value;
    });

    return axiosInstance.post(
      `${baseEndpoint}/categories/${category}/calculate`,
      { fields: fieldValues },
    ).then((res) => {
      commit('SET_CALCULATED_CHECKLIST_CATEGORY_FIELDS', {
        values: res.data.data,
        assignment,
        installation,
        checklist,
        category,
        group,
      });
    });
  }, 300),
  getChecklists({ commit }, { assignment, installation }) {
    if (!assignment || !installation) {
      return Promise.resolve(null);
    }

    const endpoint = `assignments/${assignment}/installations/${installation}/checklists`;

    return axiosInstance.get(endpoint).then((res) => {
      commit('SET_CHECKLISTS', {
        assignment,
        installation,
        checklists: res.data.data,
      });
    });
  },
  getChecklistCategories({ commit }, { assignment, installation, checklist }) {
    const endpoint = `assignments/${assignment}/installations/${installation}/checklists/${checklist}/categories`;

    return axiosInstance.get(endpoint).then((res) => {
      commit('SET_CHECKLIST_CATEGORIES', {
        assignment,
        installation,
        checklist,
        categories: res.data.data,
      });

      return res.data.data;
    });
  },
  getChecklistCategoryFields({ commit }, { assignment, installation, checklist, category }) {
    const baseEndpoint = generateBaseEndpoint(
      assignment,
      installation,
      checklist,
    );

    const params = new URLSearchParams({
      // XDEBUG_TRIGGER: 'StartProfileForMe',
    });

    return axiosInstance.get(`${baseEndpoint}/categories/${category}/fields?${params.toString()}`)
      .then((res) => {
        commit('SET_CHECKLIST_CATEGORY_FIELDS', {
          fields: res.data.data,
          assignment,
          installation,
          checklist,
          category,
        });
      });
  },
  getChecklistCategoryValues({ commit }, { assignment, installation, checklist, category }) {
    const baseEndpoint = generateBaseEndpoint(
      assignment,
      installation,
      checklist,
    );

    const params = new URLSearchParams({
      // XDEBUG_TRIGGER: 'StartProfileForMe',
    });

    axiosInstance.get(`${baseEndpoint}/categories/${category}/values?${params.toString()}`)
      .then((res) => {
        commit('SET_CHECKLIST_CATEGORY_VALUES', {
          values: res.data.data,
          assignment,
          installation,
          checklist,
          category,
        });
      });
  },
  removeRowFromGroupedFields(
    { commit, dispatch },
    { assignment, installation, checklist, category, itemIndex, group, rowIndex, hasCalculatedFields },
  ) {
    commit(
      'REMOVE_ROW_FROM_GROUPED_FIELDS',
      { assignment, installation, checklist, category, itemIndex, group, rowIndex },
    );

    if (hasCalculatedFields) {
      dispatch(
        'getCalculatedValuesForChecklist',
        { assignment, installation, checklist, category },
      );
    }
  },
  saveValuesForChecklistCategory({ commit, state }, { assignment, installation, checklist, category }) {
    const baseEndpoint = generateBaseEndpoint(
      assignment,
      installation,
      checklist,
    );

    const values = state
      .checklistValues
      ?.[assignment]
      ?.[installation]
      ?.[checklist]
      ?.[category];

    return axiosInstance.put(`${baseEndpoint}/categories/${category}`, {
      fields: values,
    }).then((res) => {
      commit('MARK_CATEGORY_AS_CLEAN', {
        assignment,
        installation,
        checklist,
        category,
      });

      return res;
    });
  },
  updateCategoryFieldValue(
    { commit, dispatch },
    { assignment, installation, checklist, category, field, group, rowIndex, value },
  ) {
    commit(
      'UPDATE_CATEGORY_FIELD_VALUE',
      { assignment, installation, checklist, category, field, group, rowIndex, value },
    );

    if (category.has_calculated_fields) {
      dispatch(
        'getCalculatedValuesForChecklistCategory',
        { assignment, installation, checklist, category: category.id, group, rowIndex },
      );
    }
  },
  updateCategoryFieldComments(
    { commit },
    { assignment, installation, checklist, category, field, group, rowIndex, comments },
  ) {
    commit(
      'UPDATE_CATEGORY_FIELD_COMMENTS',
      { assignment, installation, checklist, category, field, group, rowIndex, comments },
    );
  },
  updateCategoryFieldMeta(
    { commit },
    { assignment, installation, checklist, category, field, group, rowIndex, meta },
  ) {
    commit(
      'UPDATE_CATEGORY_FIELD_META',
      { assignment, installation, checklist, category, field, group, rowIndex, meta },
    );
  },
};
