import { Loading } from 'quasar';

export default {
  methods: {
    showLoading(message) {
      Loading.show({
        message,
        spinnerColor: 'cyan',
        backgroundColor: 'black',
        spinnerSize: '30',
      });
    },

    hideLoading() {
      Loading.hide();
    },
  },
};
