import cloneDeep from 'lodash.clonedeep';

export default {
  setFilters(state, filterData) {
    const module = Object.keys(filterData);
    state.filters[module] = cloneDeep(filterData[module]);
  },

  clearFilters(state) {
    state.filters = {};
  },
};
