export default {
  methods: {
    getInitials(user) {
      const userInitials = user.profile.initials;
      const initials = userInitials || this.makeInitials(user);
      return initials;
    },

    makeInitials(user) {
      const fullName = `${user.profile.first_name} ${user.profile.last_name}`;
      const initials = fullName.match(/(\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('')
        .toUpperCase();
      this.updateUser(user, initials);
      return initials;
    },

    updateUser(user, initials) {
      const params = {
        initials,
        first_name: user.profile.first_name,
        last_name: user.profile.last_name,
        email: user.profile.email,
        certificate: user.profile.certificate,
      };

      return this.$axios.put('me', params)
        .then((response) => {
          if (response) {
            // update user
            this.$store.commit('UPDATE_ME', response.data.data);
          }
        })
        .catch((error) => {
          if (error) {
            this.handleError(error);
            console.warn(error);
          }
        });
    },
  },
};
