/*
export function someMutation (state) {
}
*/
export default {

  startMutation(state, data) {
    state.mutation = {
      ...state.mutation,
      ...data,
    };
  },

  saveMutation(state, data) {
    state.mutationLoaded = true;
    state.mutation = data;
  },

  resetMutation(state) {
    state.mutationLoaded = false;
    state.mutation = {};
  },
};
