import { axiosInstance } from '../../boot/axios';

export default {
  getMe({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      try {
        axiosInstance
          .get('me')
          .then((response) => {
            if (response) {
              // if user have language settings use them
              const userLang = !response.data.data.profile.preferred_language
                ? ''
                : response.data.data.profile.preferred_language;

              const usergroups = response.data.data.groups.map(
                (group) => group.name,
              );

              // get strings according to language settings (nl or en)
              dispatch('getStrings', userLang);

              if (!usergroups.includes('Relations')) {
                dispatch('getUserPreferences');
              }

              // Mutate state
              commit('UPDATE_ME', response.data.data);

              window.Echo.private(`App.Models.User.${response.data.data.id}`).notification(
                (notification) => {
                  switch (notification.event) {
                    case 'maintenance-mode-enabled':
                      commit('MAINTENANCE_MODE_ENABLED', notification.secret);
                      break;

                    case 'maintenance-mode-disabled':
                      commit('MAINTENANCE_MODE_DISABLED');
                      break;

                    default:
                      if (process.env.DEV) {
                        console.log({ notification });
                      }

                      break;
                  }
                },
              );

              resolve(response);
            }
          })
          .catch((error) => {
            // Log action and status.
            console.warn(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },

  newVersionAvailable({ commit }, version) {
    commit('NEW_VERSION_AVAILABLE', version);
  },

  getUserPreferences({ commit }) {
    return axiosInstance
      .get('users-preferences')
      .then((response) => {
        if (response) {
          // Commit response data to me store
          commit('SET_USER_PREFERENCES', response.data.data);
        }
      })
      .catch((error) => {
        // Log action and status.
        console.warn(error);
        commit('SET_USER_PREFERENCES', []);
      });
  },

  updateUserPreference({ commit }, { preference }) {
    commit('UPDATE_USER_PREFERENCE', { preference });
  },

  updateVuexUserPreferences({ commit }, response) {
    commit('SET_USER_PREFERENCES_MODULE', response.data.data);
  },

  addVuexUserPreferences({ commit }, response) {
    commit('ADD_USER_PREFERENCES_MODULE', response.data.data);
  },

  clearMe({ commit }) {
    commit('CLEAR_ME');
  },
};
