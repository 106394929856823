import _cloneDeep from 'lodash.clonedeep';

export default {
  emits: ['update:filters'],
  data() {
    return {
      // @TODO After refactoring all overviews make use of emit instead of the old direct assigning filters to a value.
      emitFilter: [
        'maintenance-contracts',
        'assignments',
        'installations',
      ].includes(this.$route.name),
    };
  },
  methods: {
    resetFilters() {
      // Clone the 'filters' object (prevents binding to the store state directly)
      this.filters = _cloneDeep(this.filters);
      Object.entries(this.filters).forEach((entry) => {
        const key = entry[0];
        const val = entry[1];
        if (Array.isArray(this.filters[key])) {
          this.filters[key] = [];
        } else {
          this.filters[key] = typeof val === 'string' ? '' : null;
        }
      });

      // Update the filters in the store
      this.$store.dispatch('setFilters', {
        name: this.$route.name,
        filters: this.filters,
      });
    },

    // isActiveFilter is passed to the activeFilters computed property.
    // Here we check each filter type and return the expected value (true/false).
    isActiveFilter(filter) {
      let active = false;
      switch (typeof filter) {
        case 'string':
          // Empty strings return false.
          active = filter !== '';
          break;
        case 'object':
          // Null returns false. (indeterminate state on checkbox with 3 values).
          if (filter === null) {
            break;
          } else {
            // When the filter is an array, empty arrays return false. Empty objects return false.
            active = Array.isArray(filter) ? !!filter.length : filter !== {};
          }
          break;
        case 'boolean':
          // Both true and false values are considered active filters.
          active = true;
          break;
        default:
          break;
      }
      return active;
    },

    // Count up the values are done on advanced search.
    advancedSearchCounterCheck(filters) {
      const routeName = this.$route.name;
      let countUpFilters = 0;

      if (routeName === 'users') {
        if (filters?.full_name) countUpFilters += 1;
        if (filters?.email) countUpFilters += 1;
        if (filters?.is_active !== null) countUpFilters += 1;
      } else if (routeName === 'installations') {
        if (filters?.genre?.length ?? 0) countUpFilters += 1;
        if (filters?.owner) countUpFilters += 1;
        if (filters?.location) countUpFilters += 1;
        if (filters?.number) countUpFilters += 1;
        if (filters?.refrigerant?.length ?? 0) countUpFilters += 1;
        if (filters?.is_active !== null) countUpFilters += 1;
      } else if (routeName === 'cylinders') {
        if (filters?.location?.length ?? 0) countUpFilters += 1;
        if (filters?.number) countUpFilters += 1;
        if (filters?.refrigerant?.length ?? 0) countUpFilters += 1;
      } else if (routeName === 'assignments') {
        if (filters?.client) countUpFilters += 1;
        if (filters?.reason?.length ?? 0) countUpFilters += 1;
        if (filters?.installations) countUpFilters += 1;
        if (filters?.mechanic) countUpFilters += 1;
        if (filters?.number) countUpFilters += 1;
        if (filters?.status?.length ?? 0) countUpFilters += 1;
        if (filters?.invoiced !== null) countUpFilters += 1;
        if (filters?.maintenance_contract_number) countUpFilters += 1;
        if (filters?.has_maintenance_contract !== null) countUpFilters += 1;
      } else if (routeName === 'equipment') {
        if (filters?.brand) countUpFilters += 1;
        if (filters?.mechanic) countUpFilters += 1;
        if (filters?.number) countUpFilters += 1;
        if (filters?.status?.length ?? 0) countUpFilters += 1;
      } else if (routeName === 'maintenance-contracts') {
        if (filters?.client) countUpFilters += 1;
        if (filters?.number) countUpFilters += 1;
        if (filters?.installation) countUpFilters += 1;
      }

      return countUpFilters;
    },
  },

  computed: {
    activeFilters() {
      // Returns true when there is one or more active filters
      if (this.filters) {
        return !!Object.values(this.filters).filter(this.isActiveFilter).length;
      }

      return [];
    },
  },

  mounted() {
    // While mounting always apply the filtered searches to the module we are navigating to.
    const storeFilters = this.$store.state.filtersStore.filters[this.$route.name];

    if (this.emitFilter && storeFilters && !storeFilters.general) {
      this.emitFilter
        ? this.$emit('update:filters', storeFilters)
        : (this.filters = storeFilters);

      // Check if there is a search within the advanced search.
      this.advancedSearchCounterCheck(this.$route.name, storeFilters);
    }
  },
};
