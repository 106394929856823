/*
export function someAction (context) {
}
*/

export default {
  startMutation({ commit }, mutation) {
    commit('startMutation', mutation);
  },

  saveMutation({ commit }, mutation) {
    commit('saveMutation', mutation);
  },

  resetMutation({ commit }) {
    commit('resetMutation');
  },
};
