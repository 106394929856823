import { axiosInstance } from '../../boot/axios';

export default {
  getSubscription({ commit }, temporarySignedSubscriptionUrl = null) {
    // Capture the response data for menu and website.
    return axiosInstance.get(temporarySignedSubscriptionUrl ?? 'subscription')
      .then((response) => {
        // Commit response data to navigation store
        commit('setSubscription', response.data.data);

        return response;
      })
      .catch((error) => {
        if (error.response.status === 404 && !process.env.DEV) {
          window.location.href = 'https://climatools.nl';
        }

        // Log action and status.
        console.error(error);
      });
  },

};
