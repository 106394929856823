export default {
  // Default fallback data for settings. (during login new values will be checked from backend).
  settings: {
    cylinder: {
      gross_weight: {
        min: 0,
        max: 80,
      },
      tare_weight: {
        min: 2,
        max: 99,
        margin: 0.1,
      },
      warning: {
        critical: 3,
        medium: 6,
      },
    },
    installations: {
      circuit: {
        additional_charge: {
          min: 0,
        },
        factory_charge: {
          min: 0,
        },
      },
      power: {
        cooling: {
          min: 0,
        },
        electrical: {
          min: 0,
        },
      },
      workload: {
        lp: {
          min: 0,
        },
        hp: {
          min: 0,
        },
      },
    },
    mutations: {
      margin: 0.05,
      reason: {
        min: 5,
        max: 250,
      },
    },
  },
  settingsLoaded: false,
};
