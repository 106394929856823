import _ from 'lodash';
import { useStore } from 'vuex';

const cached = {};

/**
 * @param {String} string
 * @param {String} key
 * @param {unknown} value
 * @returns {String}
 *
 * @source https://github.com/laravel/framework/blob/0c9b3e9f53166ff95abba87d1489f70c6e828fda/src/Illuminate/Translation/Translator.php#L227
 */
function replace(string, key, value) {
  const shouldReplace = {};

  shouldReplace[`:${key.toLocaleLowerCase()}`] = typeof value === 'string' ? value.toLocaleLowerCase() : value;
  shouldReplace[`:${key.toLocaleUpperCase()}`] = typeof value === 'string' ? value.toLocaleUpperCase() : value;
  shouldReplace[`:${key[0].toLocaleUpperCase() + key.slice(1)}`] = typeof value === 'string' ? value[0].toLocaleUpperCase() + value.slice(1) : value;

  // old format
  shouldReplace[`%${key}%`] = value;

  Object.entries(shouldReplace).forEach(({ 0: key, 1: value }) => {
    string = string.replaceAll(key, value);
  });

  return string;
}

export function translate(store) {
  return (path, replacements = {}) => {
    let string = _.get(store, path, path);

    const cacheKey = JSON.stringify({ path, replacements, string });

    if (cached[cacheKey]) {
      return cached[cacheKey];
    }

    try {
      // should only replace arguments if path returned an actual string
      if (typeof string === 'string') {
        Object.entries(replacements).forEach(({ 0: key, 1: value }) => {
          string = replace(string, key, value);
        });
      }

      cached[cacheKey] = string;

      return string;
    } catch (err) {
      console.log({ err, path, string, replacements });

      return string;
    }
  };
}

// components using composition API
export function useTranslator() {
  const store = useStore();
  const stringsStore = store?.state?.stringsStore?.strings;

  return {
    translate: (path, replacements = {}) => translate(stringsStore)(path, replacements),
  };
}

// components using options API
export default {
  methods: {
    translate(path, replacements = {}) {
      return translate(this.$store.state.stringsStore.strings)(path, replacements);
    },
  },
};
