import { useQuasar } from 'quasar';

export function useCheckDevice() {
  const $q = useQuasar();

  return {
    displayMode() {
      return this.isIos() || this.isMobile() ? 'dialog' : 'menu';
    },
    isChrome() {
      return $q.platform.is.chrome ?? false;
    },
    isIos() {
      return $q.platform.is.ios ?? false;
    },
    isMobile() {
      return $q.platform.is.mobile ?? false;
    },
    isMobileIos() {
      return $q.platform.is.iphone ?? false;
    },
  };
}

export default {
  methods: {
    // Check if the device is an smartphone or a tablet device
    isMobile() {
      let isMobile = false;
      if (this.$q.platform.is.mobile) { isMobile = true; }
      return isMobile;
    },

    // Check if the device is an ios smartphone or a tablet device
    isMobileIos() {
      let isMobileIos = false;
      if (this.$q.platform.is.iphone) { isMobileIos = true; }
      return isMobileIos;
    },

    // Check if the device is an ios device
    isIos() {
      let isIos = false;
      if (this.$q.platform.is.ios) { isIos = true; }
      return isIos;
    },

    // Check if the browser is an chrome browser.
    isChrome() {
      let isChrome = false;
      if (this.$q.platform.is.chrome) { isChrome = true; }
      return isChrome;
    },

    // Determine which device should have the correct q-select options mode.
    deviceDisplayMode() {
      return this.isIos() === true ? 'dialog' : (this.isMobile() ? 'dialog' : 'menu');
    },
  },
};
