import { Notify } from 'quasar';

function notification(message, color, options = {}) {
  return Notify.create({
    message,
    color,
    timeout: 2500,
    position: 'top-right',
    actions: [{ icon: 'close', color: 'white' }],

    // allow overriding any of the default options above,
    // as well as include any additional options
    // allowed by Quasar's Notify API
    ...options,
  });
}

export default {
  methods: {
    notification,

    errorNotification(message, options = {}) {
      notification(message, 'negative', options);
    },
    infoNotification(message, options = {}) {
      notification(message, 'info', options);
    },
    successNotification(message, options = {}) {
      notification(message, 'positive', options);
    },
  },
};

export function useNotification() {
  return {
    notification,
  };
}
