import { boot } from 'quasar/wrappers';
import { createGtm } from '@gtm-support/vue-gtm';

export default boot(({ app, store, router }) => {
  // for use inside Vue files through this.$store
  app.config.globalProperties.$store = store;

  // for use inside Vue files through this.$router
  app.config.globalProperties.$router = router;

  const gtmId = process.env.VUE_APP_GTM_ID;

  if (gtmId) {
    app.use(
      createGtm({
        id: gtmId,
        compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        nonce: '2726c7f26c', // Will add `nonce` to the script tag
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: process.env.DEV, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        vueRouterAdditionalEventData: () => {
          const me = store.state.meStore?.me;
          const subscription = store.state.subscriptionStore?.subscription;

          return {
            userId: me?.public_id,
            subscriptionId: subscription?.public_id,
            subscriptionType: subscription?.subscription_package,
          };
        },
        trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
      }),
    );
  }
});
