import { axiosInstance } from '../../boot/axios';

export default {
  async getStatus({ commit }) {
    try {
      const response = await axiosInstance.get('status');

      commit('STATUS_RETRIEVED', response.data);

      if (response.data.down) {
        commit('MAINTENANCE_MODE_ENABLED', response.data.bypassed ? '-' : undefined);
      }

      return response;
    } catch (err) {
      console.error(err);

      return null;
    }
  },
};
