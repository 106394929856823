import { useStore } from 'vuex';

function isLitePackage(subscriptionStore) {
  return subscriptionStore.subscription_package === 'Lite';
}
function isBasicPackage(subscriptionStore) {
  return subscriptionStore.subscription_package === 'Basic';
}
function isProfessionalPackage(subscriptionStore) {
  return subscriptionStore.subscription_package === 'Professional';
}
function isEnterprisePackage(subscriptionStore) {
  return subscriptionStore.subscription_package === 'Enterprise';
}
function isCustomPackage(subscriptionStore) {
  return subscriptionStore?.subscription_package === 'custom';
}
function isStekCertified(subscriptionStore) {
  return subscriptionStore?.company?.is_stek_certified ?? false;
}

// components using composition API
export function useSubscriptionPackageChecker() {
  const store = useStore();
  const subscriptionStore = store.state.subscriptionStore.subscription;

  return {
    isLitePackage() {
      return isLitePackage(subscriptionStore);
    },
    isBasicPackage() {
      return isBasicPackage(subscriptionStore);
    },
    isProfessionalPackage() {
      return isProfessionalPackage(subscriptionStore);
    },
    isEnterprisePackage() {
      return isEnterprisePackage(subscriptionStore);
    },
    isCustomPackage() {
      return isCustomPackage(subscriptionStore);
    },
    isStekCertified() {
      return isStekCertified(subscriptionStore);
    },
  };
}

// components using options API
export default {
  data() {
    return {
      subscriptionStore: this.$store.state.subscriptionStore.subscription,
    };
  },
  methods: {
    isLitePackage() {
      return isLitePackage(this.subscriptionStore);
    },
    isBasicPackage() {
      return isBasicPackage(this.subscriptionStore);
    },
    isProfessionalPackage() {
      return isProfessionalPackage(this.subscriptionStore);
    },
    isEnterprisePackage() {
      return isEnterprisePackage(this.subscriptionStore);
    },
    isCustomPackage() {
      return isCustomPackage(this.subscriptionStore);
    },
    isStekCertified() {
      return isStekCertified(this.subscriptionStore);
    },
  },
};
