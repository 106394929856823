<template>
  <div class="full-width">
    <!-- Select for autocomplete -->
    <q-select
      ref="autocomplete"
      v-model="computedValue"
      stack-label
      :hide-selected="!multipleProp"
      hide-bottom-space
      use-input
      fill-input
      hide-dropdown-icon
      no-error-icon
      input-debounce="300"
      popup-content-class="q-select-options"
      :option-value="optionValue"
      :option-label="(opt) => Object(opt) === opt && optionLabel in opt ? getString(opt[optionLabel], opt[optionLabel]) : null"
      :multiple="multipleProp"
      :use-chips="multipleProp"
      :readonly="readonlyProp"
      :label="labelProp"
      :placeholder="placeholderProp"
      :error="errorProp"
      :rules="validateFields"
      :autocomplete="autocompletePath"
      :options="filterOptions"
      :loading="loadingOptions"
      :behavior="deviceDisplayMode()"
      :outlined="outlined"
      @filter="autocompleteFilter"
    >
      <!-- Multiple options with Chips Slot -->
      <template #selected-item="item">
        <q-chip
          v-if="multipleProp && computedValue"
          :removable="!readonlyProp"
          :tabindex="item.tabindex"
          color="primary"
          text-color="white"
          :label="item.opt[optionLabel]"
          @remove="item.removeAtIndex(item.index)"
        />
      </template>
      <!-- No results found Slot -->
      <template #no-option>
        <q-item>
          <q-item-section class="text-grey">
            {{
              stringReplace(
                'autocomplete.nothingFound',
                'autocomplete.nothingFound',
                ['%type%', parsedLabelProp.toLowerCase()],
              )
            }}
          </q-item-section>
        </q-item>
      </template>
      <!-- Loading Spinner Slot -->
      <template #loading>
        <q-item>
          <q-item-section>
            <q-spinner
              v-show="loadingOptions"
              color="primary"
              size="1.3em"
              :thickness="2"
            />
          </q-item-section>
        </q-item>
      </template>
      <!-- Clear Input Slot -->
      <template
        v-if="clearableProp"
        #append
      >
        <q-item>
          <q-item-section>
            <q-icon
              v-if="computedValue[optionLabel] !== '' && !loadingOptions"
              class="cursor-pointer right"
              name="clear"
              @click.stop="computedValue = { [optionValue]: '', [optionLabel]: '' }"
            />
          </q-item-section>
        </q-item>
      </template>
      <!-- Address for clients: company and relations -->
      <template
        v-if="autocompletePath === 'companies' || autocompletePath === 'relations'"
        #option="scope"
      >
        <q-item v-bind="scope.itemProps">
          <q-item-section>
            <q-item-label>{{ scope.opt.label }}</q-item-label>
            <q-item-label caption>
              {{ getString('address.city') }}: {{ scope.opt.address?.city }}
            </q-item-label>
            <q-item-label caption>
              {{ getString('address.street') }}: {{ scope.opt.address?.street }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Autocomplete',
  props: {
    optionValue: {
      type: String,
      default: 'value',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    modelValue: {
      type: [Number, String, Boolean, Array, Object],
      default: () => ({}),
    },
    readonlyProp: {
      type: Boolean,
      default: false,
    },
    labelProp: {
      type: String,
      default: '',
    },
    placeholderProp: {
      type: String,
      default: '',
    },
    // path to be used on the request
    autocompletePath: {
      type: String,
      default: '',
    },
    autocompleteOptions: {
      type: Array,
      default: () => [],
    },
    errorProp: {
      type: Boolean,
      default: false,
    },
    clearableProp: {
      type: Boolean,
      default: false,
    },
    // input requirements to be validated. ex:['required', 'email']
    validations: {
      type: Array,
      default: () => [],
    },
    /* when no more options are available after filter,
     * should it be allowed to add a new value or not
     */
    canAddNewValue: {
      type: Boolean,
      default: false,
    },
    multipleProp: {
      type: Boolean,
      default: false,
    },
    resolveOnMount: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      filterOptions: [],
      loadingOptions: false,
      parsedLabelProp: this.labelProp.includes('*')
        ? this.labelProp.replace('*', '')
        : this.labelProp,
      rules: {
        required: (val) => val?.label !== ''
          || this.stringReplace(this.computedValue, 'form.error.required', [
            '%possesivePronoun%%input%',
            `${
              this.labelProp.includes('*')
                ? this.labelProp.replace('*', '')
                : this.labelProp
            }`,
          ]),
      },
      lastNeedle: ref(),
    };
  },

  computed: {
    // at input, emit the updated value
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
    // check if validations exists and send message for each rule
    validateFields() {
      const returnRules = [];

      this.validations.forEach((rule) => {
        if (this.rules[rule]) {
          returnRules.push(this.rules[rule]);
        }
      });

      return returnRules;
    },
  },

  watch: {
    // Refreshing autocomplete options to show content when clicked on.
    filterOptions() {
      if (this.$refs.autocomplete) {
        this.$refs.autocomplete.refresh();
      }
    },
  },

  mounted() {
    if (this.resolveOnMount && this.$store.state.authStore.isAuthenticated) {
      this.getOptions(this.autocompletePath);
    }
  },

  methods: {
    /**
     * When importing and using this functionality in your module
     * user will be able to filter on a requested list.
     * Props can be adjusted to work on any endpoint and its customizable.
     */
    async autocompleteFilter(val, update) {
      if (val === this.lastNeedle) {
        update();

        return;
      }

      this.loadingOptions = true;
      const needle = val.trim();
      const params = new URLSearchParams();

      if (needle.length > 0) {
        params.set('query', needle);
      }

      // request to the autocomplete api
      await this.$axios
        .get(`${this.autocompletePath}?${params.toString()}`)
        .then((res) => res.data.data)
        .then((data) => {
          // store the result from autocomplete with correct list format
          this.filterOptions = data.map(this.getOptionResponseFormat);

          if (this.canAddNewValue && needle.length > 0) {
            this.filterOptions.push({
              label: this.stringReplace(val, 'autocomplete.clickToAdd', [
                '%input%',
                `${val}`,
              ]),
              value: 'new',
              newLabel: val,
              newValue: this.getNewValueResponseFormat(val),
            });
          }
        })
        .catch((error) => {
          if (process.env.DEV) {
            console.error(error);
          }

          this.handleError(error);
          return [];
        });

      this.lastNeedle = needle;
      this.loadingOptions = false;

      update();
    },

    /**
     * Switch the response format of the list of options,
     * depending on the endpoint.
     */
    getOptionResponseFormat(option) {
      switch (this.autocompletePath) {
        case 'companies':
          return {
            id: option.id,
            value: option.id,
            label: option.name,
            name: option.name,
            address: option.address,
            _option: option,
          };

        case 'relations':
          return {
            id: option.id,
            value: option.id,
            label: option.profile.full_name,
            name: option.profile.full_name,
            address: option.profile.address,
            _option: option,
          };

        case 'skills':
          return {
            id: option.id,
            value: option.id,
            label: option.label,
            _option: option,
          };

        case 'installation-genre-types/gas_boiler/device-codes':
        case 'installation-genre-types/gas_boiler/burner-types':
          return {
            id: option.id,
            value: option.id,
            label: this.getString(option.g11n_code, option.g11n_code),
            name: option.code,
            _option: option,
          };

        default:
          if (this.autocompletePath.includes('/objects')) {
            return {
              id: option.id,
              value: option.id,
              label: option.type
                ? `${option.number} - ${option.type.label}`
                : option.number,
              _option: option,
            };
          }

          return {
            id: option.id,
            value: option.id,
            label: this.getString(option.label, option.label),
            _option: option,
          };
      }
    },

    /**
     * If autocomplete is allowed to add a new value, when there
     * are no more options, switch the response format of the new value,
     * depending on the endpoint, so it can be used in the popup.
     *
     * @param {String} val
     */
    getNewValueResponseFormat(val) {
      switch (this.autocompletePath) {
        case 'relations': {
          /** @type {Array} name */
          const name = val.split(' ');

          return {
            firstName: name.at(0),
            lastName: name.slice(1).join(' '),
          };
        }

        default:
          return val;
      }
    },

    async getOptions(path) {
      try {
        this.filterOptions = await this.$axios
          .get(path)
          .then((res) => res.data.data)
          .then((data) => data.map((option) => this.getOptionResponseFormat(option)));
      } catch (err) {
        if (process.env.DEV) {
          console.warn(err);
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import 'src/css/quasar.variables.sass'

.q-select-options
  max-height: 200px
</style>
