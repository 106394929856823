import { baseApiAxiosInstance } from '../../boot/axios';

export default {
  getStrings({ commit }, lang) {
    // check if user have a prefered language
    const endpoint = ['lang', lang].filter((value) => !!value).join('/');

    return baseApiAxiosInstance.get(endpoint)
      .then((response) => {
        // Succeeded -> mutate state
        commit('updateStrings', response.data.data);
      }).catch((error) => {
        // Log action and status.
        console.warn('error: ', error);
      });
  },
};
