import { axiosInstance } from '../../boot/axios';

export default {
  getNavigation({ commit }) {
    return axiosInstance.get('menu')
      .then((response) => {
        if (response) {
        // Commit response data to navigation store
          commit('setMenu', response.data.data);
        }
      })
      .catch((error) => {
      // Log action and status.
        console.warn(error);
      });
  },

};
