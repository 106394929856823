<template>
  <div class="full-width">
    <q-input
      ref="inputField"
      v-model="computedValue"
      class="input-field"
      stack-label
      no-error-icon
      lazy-rules
      :type="inputType"
      :autogrow="autogrow"
      :color="colorProp"
      :outlined="outlinedProp"
      :placeholder="placeholderProp"
      :hint="hintProp"
      :label="labelProp"
      :dense="denseProp"
      :readonly="readonlyProp"
      :rules="validateFields"
      :counter="counterProp"
      :maxlength="maxlengthProp || null"
      :error="errorProp"
      :clearable="fieldClearable"
    />
  </div>
</template>

<script>
import Regex from '../../mixins/regex/regex.js';

export default {
  name: 'TextField',

  mixins: [Regex],
  props: {
    autogrow: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    outlinedProp: {
      type: Boolean,
      default: false,
    },
    denseProp: {
      type: Boolean,
      default: false,
    },
    counterProp: {
      type: Boolean,
      default: false,
    },
    maxlengthProp: {
      type: Number,
      default: 0,
    },
    readonlyProp: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: '',
    },
    colorProp: {
      type: String,
      default: 'grey-5',
    },
    placeholderProp: {
      type: String,
      default: '',
    },
    labelProp: {
      type: String,
      default: '',
    },
    hintProp: {
      type: String,
      default: '',
    },
    validations: {
      // ['required', 'email']
      type: Array,
      default: () => [],
    },
    errorProp: {
      type: Boolean,
      default: false,
    },
    // Pass a value to this component so we can accurately check on different regex situations.
    checkOnValue: {
      type: [Array, String, Boolean],
      default: () => '',
    },
    // Optional value, if true is given the input field can be cleared.
    fieldClearable: {
      type: Boolean,
      default: false,
    },
    countryZipcodeValidation: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      nameRegex: /^[a-zA-Z]([\w -]*[a-zA-Z])?$/,
      streetnumberRegex: /^\d{1,4}(?:[a-zA-z]{1,2}\d{0,3})?$/,
      streetnumberExtRegex: /[A-Za-z0-9'.\-\s,]/,
      rules: {
        required: (val) => !!val
          || this.stringReplace('form.error.required', 'form.error.required', [
            '%possesivePronoun%%input%',
            `${this.$props.labelProp.includes('*')
              ? this.$props.labelProp.replace('*', '')
              : this.$props.labelProp
            }`,
          ]),
        explanation: (val) => !!val
          || this.translate('module.mutations.wizard.extensive_reason.error'),
        email: (val) => val.match(this.emailCheckRegex)
          || this.translate('form.error.pattern.email'),
        name: (val) => val.match(this.nameRegex)
          || this.stringReplace(val, 'form.error.pattern.default', [
            '%possesivePronoun%%input%',
            `${this.$props.labelProp}`,
          ]),
        password: (val) => val.length > 5
          || this.stringReplace(
            val,
            'form.error.pattern.min',
            ['%input%', `${this.$props.labelProp}`],
            ['%min%', '5'],
          ),
        zipcode: (val) => val.match(this.countryZipcodeValidation[this.checkOnValue])
          || this.translate('address.invalid.zipcode'),
        streetnumber: (val) => val.match(this.streetnumberRegex)
          || this.translate('address.invalid.streetnumber'),
        extension: (val) => val.match(this.streetnumberExtRegex)
          || this.translate('address.invalid.streetnumber_extension'),
      },
    };
  },

  computed: {
    validateFields() {
      const returnRules = [];
      this.validations.forEach((rule) => {
        if (this.rules[rule]) {
          returnRules.push(this.rules[rule]);
        }
      });
      return returnRules;
    },
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<style lang="sass">
@import '../../css/quasar.variables.sass'
.input-field
  padding: 0
  width: 100%
  color: $grey
</style>
