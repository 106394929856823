import { route } from 'quasar/wrappers';
import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
  createWebHashHistory,
} from 'vue-router';
import { Notify } from 'quasar';
import routes from './routes';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default route(({ store }) => {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    store,

    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      // eslint-disable-next-line no-void
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE,
    ),
  });

  Router.beforeEach((to, from, next) => {
    // before authentication these paths can be used as well
    if (
      !store.state.authStore.isAuthenticated
      && ![
        'login',
        'maintenance',
        'reset_request',
        'reset_response',
        'accept',
        'cylinders_card',
        'installations_card',
        'error_card',
        'inactive',
        'signed_installation_detail',
      ].includes(to.name)
    ) {
      next({ name: 'login' });

    // before entering these paths, check permissions
    // @TODO add integrations
    } else if (
      [
        'users',
        'user-groups',
        'relations',
        'companies',
        'installations',
        'cylinders',
        'mutations',
        'allocations',
        'assignments',
        'refrigerant-reports',
        'installation-reports',
        'users_invite',
        'users_detail',
        'user-groups-add',
        'user-groups-detail',
        'relations_add',
        'relations_detail',
        'companies_add',
        'companies_detail',
        'installations_add',
        'installations_detail',
        'cylinders_add',
        'cylinders_detail',
        'assignments_add',
        'assignments_detail',
        'planning',
        'scheduler',
        'agenda',
        'equipment',
        'equipment_add',
        'equipment_detail',
        'checklists',
        'checklists_add',
        'checklists_detail',
        'stocks',
        'maintenance-reports',
      ].indexOf(to.name) !== -1
    ) {
      // usergroups that user is part of
      const usergroups = store.state.authStore.user.groups.map(
        (group) => group.name,
      );

      // Check module-level route access. (users, usergroups, logs)
      const modules = store.state.meStore.me.rights
        .map((x) => x.split('.')[0])
        .filter((item, pos, self) => self.indexOf(item) === pos);

      // permission to navigate only if su or with the correct modules or rights
      const permission = store.state.authStore.user.is_superuser
        || store.state.meStore.me.rights.includes(to.meta.permission)
        || modules.includes(to.meta.permission);

      // Allow route access only when user has correct permission.
      if (permission) {
        next();
      } else {
        // notify user he does not have permission
        Notify.create({
          message: store.state.stringsStore.strings.error.permission,
          position: 'top-right',
          color: 'warning',
          timeout: 2500,
        });
        // If the user does not have the correct permission, and is type relation, redirect to /installations
        if (usergroups.includes('Relations')) {
          next({ name: 'installations' });
        } else {
          // If the user does not have the correct permission, and is not type relation, redirect to /dashboard.
          next({ name: 'dashboard' });
        }
      }
    } else {
      // usergroups that user is part of
      const usergroups = store.state.authStore.isAuthenticated ? store.state.authStore.user.groups.map(
        (group) => group.name,
      ) : false;

      // if user is type relation, when authenticated, if not in installations, redirect.
      if (store.state.authStore.isAuthenticated && usergroups.includes('Relations')) {
        if (to.name !== 'installations') {
          next({ name: 'installations' });
        }
      } else {
        // allow all other cases
        next();
      }
    }
  });
  return Router;
});
