export default {
  setFilters({ commit }, filterData) {
    // Remove null, undefined and empty strings
    if (filterData.filters === undefined) {
      filterData.filters = '';
    }
    const filters = Object.values(filterData.filters).filter(val => val != null);
    if (filters) {
      commit('setFilters', {
        [filterData.name]: filterData.filters,
      });
    }
  },

  clearFilters({ commit }) {
    commit('clearFilters');
  },
};
