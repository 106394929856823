export default {
  LOGIN_SUCCESS(state, data) {
    state.isAuthenticated = data.user.can_login;
    state.user = data.user;
    state.token = data.token;
  },

  LOGOUT(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.token = false;
  },
};
