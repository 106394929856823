import { axiosInstance } from '../../boot/axios';

export default {
  getSettings({ commit }) {
    return axiosInstance.get('settings')
      .then((res) => res.data.data)
      .then((settings) => {
        commit('setSettings', settings);

        return settings;
      })
      .catch((error) => {
        console.error(error);
      });
  },

};
