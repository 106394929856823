<template>
  <div>
    <!-- MAIN ROUTE VIEWS -->
    <router-view />

    <!-- Install dialogbox -->
    <q-dialog
      v-model="installApplication"
      position="bottom"
      seamless
    >
      <q-card>
        <q-card-section class="row">
          <span class="col-12 q-pb-xs popup__headertext">
            Install Climatools
          </span>
          <span class="col-12">
            You can install Climatools on your device!
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <!-- Install btn -->
          <q-btn
            class="ct_button__medium"
            no-caps
            unelevated
            color="positive"
            label="Install"
            @click="installApplicationDialog()"
          />
          <!-- Cancel button -->
          <q-btn
            v-close-popup
            class="ct_button__medium"
            no-caps
            flat
            :label="translate('dialog.confirm.cancel')"
            color="grey-6"
            @click="turnOffDialogBox()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Install dialogbox - ios->safari -->
    <q-dialog
      v-model="installApplicationIosSafari"
      position="bottom"
      seamless
    >
      <q-card>
        <q-card-section class="row">
          <span class="col-12 q-pb-xs popup__headertext">
            Install Climatools
          </span>
          <span class="col-12">
            You can install Climatools on your device, click on the following icon:
            <q-icon
              size="1.5em"
              name="img:statics/export-ios-black.svg"
            /> and then click on add to your homescreen button.
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <!-- Cancel button -->
          <q-btn
            v-close-popup
            class="ct_button__medium"
            no-caps
            unelevated
            :label="translate('action.close')"
            color="primary"
            @click="turnOffDialogBox()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Install dialogbox - ios->chrome -->
    <q-dialog
      v-model="installApplicationIosChrome"
      position="bottom"
      seamless
    >
      <q-card>
        <q-card-section class="row">
          <span class="col-12 q-pb-xs popup__headertext">
            Install Climatools
          </span>
          <span class="col-12">
            You can install Climatools on your device, if you are interested, follow these steps:
          </span>
          <span class="col-12">
            - Open Climatools website on your safari browser.
          </span>
          <span class="col-12">
            - Click on the following icon:
            <q-icon
              size="1.5em"
              name="img:statics/export-ios-black.svg"
            />
          </span>
          <span class="col-12">
            - Click on add to your homescreen button.
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <!-- Cancel button -->
          <q-btn
            v-close-popup
            class="ct_button__medium"
            no-caps
            unelevated
            :label="translate('action.close')"
            color="primary"
            @click="turnOffDialogBox()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import moment from 'moment';
import localforage from 'localforage';
import EventBus from './event-bus';

export default defineComponent({
  name: 'App',

  data() {
    return {
      // Current Date
      dateNow: moment().format('YYYY-MM-DD'),
      // Two months from current Date
      dateTwoMonthsFromNow: moment().add(3, 'M').format('YYYY-MM-DD'),
      installButtonCheck: localforage.createInstance({ name: 'ct-settings' }),
      deferredPromptChrome: null,
      appInstalledOnChrome: null,
      installApplication: false,
      installApplicationIosSafari: false,
      installApplicationIosChrome: false,
      mqStandAlone: '(display-mode: standalone)',
    };
  },

  created() {
    document.title = 'Climatools';

    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent the mini-infobar from appearing on mobile
      event.preventDefault();
      this.deferredPromptChrome = event;
    });
  },

  async beforeMount() {
    window
      .Echo
      .channel('App.Notifications')
      .listen('.app.new-version-available', (notification) => {
        this.$store.dispatch('newVersionAvailable', notification.version);
      });

    // Get status
    await this.$store.dispatch('getStatus');
    await this.setSubscriptionStyle();

    /**
     * Get string based on the user preference.
     * Check if the user is authenticated before getting this first.
     */
    if (this.$store.state.authStore.isAuthenticated) {
      this.$store.dispatch('getMe');
      this.$store.dispatch('getUserPreferences');
      this.$store.dispatch('getNavigation');
    } else {
      const useSubscriptionLanguage = ['accept'].includes(this.$route.name);

      const languageToUse = useSubscriptionLanguage
        ? this.$store.state.subscriptionStore.subscription?.company?.language.iso_639_1 ?? null
        : null;

      this.$store.dispatch('getStrings', languageToUse);
    }
  },

  mounted() {
    // Get status
    const { apiStore } = this.$store.state;
    if (this.$route.meta.group === 'auth' && apiStore.status.down && !apiStore.status.bypassed) {
      this.$router.push({ name: 'maintenance' });
    }

    // Check the current connection and use this within the store so the entire app will be aware of it.
    window.addEventListener('offline', () => {
      this.$store.dispatch('setConnected', false);
    });
    window.addEventListener('online', () => {
      this.$store.dispatch('setConnected', true);
    });

    EventBus.$on('SHOW-SUBSCRIPTION-INACTIVE-DIALOG', () => {
      this.$refs.subcompref.startInactiveDialog();
    });
    setTimeout(() => {
      // Check if the device that the user uses is a mobile device (smartphone or tablet).
      if (this.isMobile()) {
        // Trigger install button for newcoming user or check if the user have installed the app.
        this.installButtonCheck.getItem('dialog-install-date').then((res) => {
          // There is no installdialog triggered by this client, show the installdialogbox to the user.
          // or check if the date is added, and is passed two months already.
          if (this.dateNow > res || !res) {
            // Show dialogbox to install the application. IOS device first, then check rest.
            if (this.$q.platform.is.ios) {
              if (this.$q.platform.is.safari) {
                // Check also if the application is in standalone mode (this is the mode that is fullscreen installed
                // mode of the website) of the application version, if this is not true, show the installation button.
                if (!window.matchMedia(this.mqStandAlone).matches) {
                  this.installApplicationIosSafari = true;
                }
              } else if (this.$q.platform.is.chrome) {
                if (!window.matchMedia(this.mqStandAlone).matches) {
                  this.installApplicationIosChrome = true;
                }
              }
            } else if (this.$q.platform.is.android) {
              // Show dialogbox to install the application for chrome or firefox
              // eslint-disable-next-line no-lonely-if
              if (this.$q.platform.is.chrome || this.$q.platform.is.firefox) {
                // Check also if the application is in standalone mode (this is the mode that is fullscreen installed
                // mode of the website) of the application version, if this is not true, show the installation button.
                if (!window.matchMedia(this.mqStandAlone).matches) {
                  this.installApplication = true;
                }
              }
            }
          }
        });
      }
    }, 2000);
  },

  beforeUpdate() {
    if (this.$route.meta.action) {
      document.title = `${this.translate(this.$route.meta.title)} - ${this.translate(`action.${this.$route.meta.action}`)} | Climatools`;
    } else if (this.$route.meta.title) {
      document.title = `${this.translate(this.$route.meta.title)} | Climatools`;
    } else {
      document.title = 'Climatools';
    }
  },

  unmounted() {
    EventBus.$off('RELOAD-FOR-CHECKING-UPDATE');
    EventBus.$off('SHOW-SUBSCRIPTION-INACTIVE-DIALOG');
  },

  methods: {
    async setSubscriptionStyle() {
      // set colors according to subscription
      if (this.$store.state.subscriptionStore.subscriptionLoaded) {
        document.body.style.setProperty('--q-primary', this.$store.state.subscriptionStore.subscription.ui.primary);
        document.body.style.setProperty('--q-secondary', this.$store.state.subscriptionStore.subscription.ui.secondary);
        document.body.style.setProperty('--q-tertiary', this.$store.state.subscriptionStore.subscription.ui.tertiary);
        document.body.style.setProperty('--q-background', this.$store.state.subscriptionStore.subscription.ui.background);
      }

      // get subscription logo
      await this.$store.dispatch('getSubscription')
        .then(() => {
          // set colors according to subscription
          if (this.$store.state.subscriptionStore.subscriptionLoaded) {
            document.body.style.setProperty('--q-primary', this.$store.state.subscriptionStore.subscription.ui.primary);
            document.body.style.setProperty('--q-secondary', this.$store.state.subscriptionStore.subscription.ui.secondary);
            document.body.style.setProperty('--q-tertiary', this.$store.state.subscriptionStore.subscription.ui.tertiary);
            document.body.style.setProperty('--q-background', this.$store.state.subscriptionStore.subscription.ui.background);
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            window.location.href = 'https://climatools.nl';
          }
          console.warn(error);
        });
    },

    installApplicationDialog() {
      // Add a date val within database, the value is set from two months from now.
      this.installButtonCheck.setItem('dialog-install-date', this.dateTwoMonthsFromNow);

      // Turn off dialogbox
      this.installApplication = false;
      this.installApplicationIosSafari = false;
      this.installApplicationIosChrome = false;

      // Show the install prompt.
      if (this.$q.platform.is.chrome) {
        this.deferredPromptChrome.prompt();
      } else if (this.$q.platform.is.firefox) {
        this.deferredPromptChrome.prompt();
      }
    },

    turnOffDialogBox() {
      // Add a date val within database that value is set from two months from now.
      this.installButtonCheck.setItem('dialog-install-date', this.dateTwoMonthsFromNow);

      // Turn off dialogbox
      this.installApplication = false;
      this.installApplicationIosSafari = false;
      this.installApplicationIosChrome = false;
    },
  },
});
</script>
