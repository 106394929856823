// axios boot file (src/boot/axios.js)
import moment from 'moment';
import { boot } from 'quasar/wrappers';
import { Notify } from 'quasar';
import axios from 'axios';
import { useHelpers } from 'src/mixins/helpers';

const resp = await fetch(document.location.href, { method: 'HEAD' });
const headers = Object.fromEntries(resp.headers.entries());

let url = process.env.VUE_APP_BASE_API_URL;
let node = null;

console.log(JSON.stringify({ nodeInHeaders: 'x-node' in headers, headers, url }, null, 2));

if ('x-node' in headers && url?.includes('{node}')) {
  url = url.replace('{node}', node = headers['x-node']);
}

export const api = {
  node,
  dev: process.env.DEV,
  protocol: process.env.VUE_APP_BASE_API_PROTOCOL,
  url,
};

console.log(JSON.stringify({ api }, null, 2));

// We create our own axios instance and set a custom base URL.
// Note that if we wouldn't set any config here we do not need
// a named export, as we could just `import axios from 'axios'`
const axiosInstance = axios.create({
  baseURL: `${api.protocol}://${api.url}`,
  withCredentials: true,
});

const baseApiAxiosInstance = axios.create({
  baseURL: `${api.protocol}://${api.url}`,
  withCredentials: true,
});

export default boot(({ app, router, store }) => {
  // stored token
  const AUTH_TOKEN = store.state.authStore.token.token;

  const subscription = useHelpers().getActiveSubscription();

  axiosInstance.defaults.baseURL = `${api.protocol}://${subscription}.${api.url}`;

  if (AUTH_TOKEN) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
    baseApiAxiosInstance.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
  }

  /**
   * @param {AxiosResponse<any>} response
   * @returns AxiosResponse<any>
   */
  const responseHandler = (response) => {
    // If there is active subscription and user is on inactive router, return the user back to login.
    if (router.currentRoute.value.path === '/inactive') {
      router.push('/login');
    }
    return response;
  };

  const errorHandler = (error) => {
    if (error?.message === 'canceled') {
      return Promise.resolve();
    }

    switch (error.response?.status) {
      case 401: {
        store
          .dispatch('authLogout', { fromUnauthorizedRequest: true })
          .then(() => {
            if (router.currentRoute.value.path !== '/login') {
              router.push('/login');
            }
          })
          .catch((err) => {
            console.warn(err);
          });

        break;
      }
      case 403: {
        // notify user he does not have permission
        Notify.create({
          message: error.response.data.message,
          position: 'top-right',
          color: 'warning',
          timeout: 2500,
        });

        // @todo check if the 403 is thrown right after route navigation,
        // disabled until then because it throws the user to the dashboard when other
        // actions return a 403, e.g. insufficient rights within a module
        // if (router.currentRoute.value.path !== '/dashboard') {
        //   router.push('/dashboard');
        // }

        break;
      }
      case 503: {
        Notify.create({
          message: this.getString('maintenance.down'),
          position: 'top-right',
          color: 'warning',
          badgeStyle: {
            opacity: 0,
          },
        });
        break;
      }
      default:
      // do nothing
    }

    // Check if the subscription is not active.
    const dateTimeNow = moment().format('YYYY-MM-DD HH:mm:ss');
    const expiresAt = error.response.data?.expires_at;
    if (error.response?.status === 503 && dateTimeNow > expiresAt) {
      if (router.currentRoute.value.path !== '/inactive') {
        router.push('/inactive');
        document.body.style.setProperty('--q-background', '#323A45');
      }
    }
    return Promise.reject(error);
  };

  // Response interceptor for API calls
  axiosInstance.interceptors.response.use(responseHandler, errorHandler);
  baseApiAxiosInstance.interceptors.response.use(responseHandler, errorHandler);

  // for use inside Vue files through this.$axios
  app.config.globalProperties.$axios = axiosInstance;
  app.config.globalProperties.$baseApiAxios = baseApiAxiosInstance;
});

// Here we define a named export
// that we can later use inside .js files:
export { axiosInstance, baseApiAxiosInstance };
