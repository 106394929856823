import Emitter from 'tiny-emitter';

const EventBus = new Emitter();

export default {
  $on: (...args) => EventBus.on(...args),
  $once: (...args) => EventBus.once(...args),
  $off: (...args) => EventBus.off(...args),
  $emit: (...args) => EventBus.emit(...args),
};
