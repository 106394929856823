<!-- This is the base component for the Sticky Buttons which is imported in all modules that uses this. -->
<template>
  <q-page-sticky
    ref="stickBtnContent"
    expand
    position="top"
    class="sticky__content"
    :class="isMobile()
      ? (scrollInfo.position.top !== 0 ? 'sticky q-px-xs q-py-md' : 'stickymobile q-px-xs q-py-md')
      : (scrollInfo.position.top !== 0 ? 'sticky sticky--visible q-pa-lg' : 'sticky q-pa-lg')"
  >
    <q-scroll-observer @scroll="onScroll" />
    <q-toolbar class="bg-ashes">
      <div
        class="row col-12"
        :class="isMobile() ? 'q-col-gutter-sm' : 'q-col-gutter-xs'"
      >
        <!-- To overview button -->
        <span
          v-if="overviewRouteButton"
          :class="isMobile() ? 'col-6' : 'col-xs-6 col-sm-auto'"
        >
          <q-btn
            :class="isMobile() ? 'q-py-sm full-height' : 'ct_button__medium q-py-sm'"
            class="full-width"
            color="grey-5"
            :to="routeBackInstallToAssignment() ? $router.options.history.state.back : `/${overviewRouteButton}`"
            no-caps
            dense
            unelevated
          >
            <q-icon
              left
              size="1.5em"
              name="keyboard_backspace"
            />
            <div v-if="!isMobile()">
              {{ routeBackInstallToAssignment() ? getString('dialog.tunnel.back') : getString('action.toOverview') }}
            </div>
          </q-btn>
        </span>

        <!-- Reload button. -->
        <div
          v-if="reloadFunction && $store.state.connectionStore.onlineState"
          :class="isMobile() ? 'col-6' : 'q-pr-xs col-xs-6 col-sm-auto'"
        >
          <q-btn
            :class="isMobile() ? 'q-py-sm full-height' : 'ct_button__medium q-py-sm'"
            class="full-width"
            color="positive"
            no-caps
            dense
            unelevated
            :loading="reloading"
            @click="reloadFn()"
          >
            <q-icon
              left
              name="refresh"
              size="1.5em"
            />
            <div>{{ getString("action.reload") }}</div>
          </q-btn>
        </div>
        <span
          v-if="isMobile() && reloadFunction"
          style="order: 2"
        >
          {{ getString("general.lastUpdate") }}: {{ date }}
        </span>
        <!-- Reload button format time for last updated time. -->
        <div
          v-if="!isMobile() && reloadFunction"
          class="q-ml-xs q-mt-sm"
        >
          {{ getString("general.lastUpdate") }}: {{ date }}
        </div>

        <q-space v-if="!isMobile() && !$q.screen.lt.sm" />

        <!-- Serve as content distribution outlets in component templates. -->
        <slot />

        <!-- General buttons on the right section of the app. -->
        <span
          v-for="item in filteredRightItem"
          :key="item.string"
          :class="isMobile() ? (checkingForOneButton ? 'col-12': 'col-6') : 'col-xs-6 col-sm-auto'"
          @click="isMobile() ? checkingHeight() : ''"
        >
          <q-btn
            v-if="item.showContent"
            v-show="item && !item.rights ? true : userRights(item.rights)"
            :color="item.color"
            :disable="item.disable"
            :loading="item.loading"
            class="full-width"
            :class="isMobile() ? 'q-py-sm full-height' : 'ct_button__medium q-py-sm'"
            no-caps
            dense
            unelevated
            @click="item.function"
          >
            <div>{{ getString(item.string) }}</div>
            <q-icon
              v-if="item.icon"
              right
              :name="item.icon"
              size="1em"
            />

            <!-- If the there is a popup-proxy attach to the object,
            use it to create a extra menu items for the btn -->
            <q-popup-proxy
              v-if="item.btnProxyData"
            >
              <q-list style="background-color: white;">
                <div
                  v-for="actionButton in item.btnProxyData"
                  :key="actionButton.string"
                >
                  <q-item
                    v-show="actionButton && !actionButton.rights ? true : userRights(actionButton.rights)"
                    v-if="actionButton.showContent"
                    v-close-popup="!actionButton.loading"
                    class="items-center non-selectable"
                    clickable
                    :disable="actionButton.loading"
                    @click="actionButton.function"
                  >
                    <q-item-section
                      v-if="actionButton.loading"
                      side
                    >
                      <q-spinner
                        color="primary"
                        size="1.4em"
                        :thickness="2"
                      />
                    </q-item-section>
                    <q-item-section
                      v-if="actionButton.icon && !actionButton.loading"
                      side
                    >
                      <q-icon
                        v-if="actionButton.icon.startsWith('icons')
                          || actionButton.icon.startsWith('truck')
                          || actionButton.icon.startsWith('send-black-green')"
                        :name="`img:statics/${actionButton.icon}.svg`"
                        size="1.4em"
                      />
                      <q-icon
                        v-else
                        :name="actionButton.icon"
                        :color="actionButton.iconColor"
                        size="1.4em"
                      />
                    </q-item-section>
                    <q-item-section>
                      {{ getString(actionButton.string) }}
                    </q-item-section>
                  </q-item>
                </div>
              </q-list>
            </q-popup-proxy>
          </q-btn>
        </span>
      </div>
    </q-toolbar>
  </q-page-sticky>
</template>

<script>
import moment from 'moment';
import fieldFormatter from '../../mixins/table/fieldFormatters';
import checkDevice from '../../mixins/device/checkDevice';
import getStrings from '../../mixins/getStrings';

export default {
  mixins: [checkDevice, getStrings, fieldFormatter],
  props: {
    // Props for overview page.
    overviewRouteButton: {
      type: [String, Boolean],
      default: false,
    },

    // Props for reload function.
    reloadFunction: {
      type: [Boolean, Function],
      default: false,
    },

    reloading: {
      type: Boolean,
      default: false,
    },

    // Props buttons that are used for the right side.
    generalRightButtons: {
      type: [Boolean, Array, Object],
      default: false,
    },
  },

  data() {
    return {
      scrollInfo: { position: { top: 0 } },
      // Base value to return to if nothing is set.
      date: moment().format('HH:mm'),
    };
  },

  computed: {
    // Filter out the button data that is not set the showContent on true.
    filteredRightItem() {
      if (this.generalRightButtons) {
        return this.generalRightButtons?.filter(e => e.showContent) ?? [];
      }

      return [];
    },

    /**
     * Check if there is only one button available
     * for right button section and show this in
     * full length. But check if there is an
     * overview or reload button available for the
     * left side.
     */
    checkingForOneButton() {
      return !this.overviewRouteButton
        && !this.reloadFunction
        && this.filteredRightItem.length === 1;
    },
  },

  mounted() {
    // this.checkingHeight();
  },

  methods: {
    routeBackInstallToAssignment() {
      let val = false;
      if (this.$router.options.history.state.back !== null
        && this.$router.options.history.state.back.includes('assignments/')
        && this.$router.options.history.state.current.includes('installations/')) {
        val = true;
      }
      return val;
    },
    /**
     * Some buttons needs be removed and some needs
     * to be visible during the offline stage. With
     * this function we add the exceptions to the
     * buttons we want to see from the modules.
     */
    offlineExeption() {
      const isOnline = this.$store.state.connectionStore.onlineState;
      const route = this.$route.name;
      let showButton = true;

      if (!isOnline) {
        const exceptions = route === 'AssignmentsDetail'
        || route === 'AssignmentsAdd';

        showButton = exceptions;
      } else {
        // We are online show all of the buttons
        showButton = true;
      }

      // The app should be online offline to be able to see them.
      return showButton;
    },

    // Get scroll position Y for sticky buttons
    onScroll(info) {
      this.scrollInfo.position.top = info.position.top;
    },

    reloadFn() {
      // Reload the dates.
      this.date = moment().format('HH:mm');
      // Reload the methods
      this.reloadFunction();
    },

    checkingHeight() {
      /**
       * Initiate the content based on the height of the
       * sticky-buttons. So the content can be pushed
       * down if there is to much buttons in the
       * sticky button area.
       */
      this.$emit('stickyButtonSectionHeight', {
        'padding-top': `${this.$refs.stickBtnContent.$el.offsetHeight - 70}px`,
      });
    },
  },

};
</script>

<style lang="sass" scoped>
@import '../../css/quasar.variables.sass'

.sticky__content
  z-index: 2000

.sticky
  background-color: $ashes
  transition: box-shadow .2s ease
  &--visible
    box-shadow: 0 4px 4px -3px rgba(0, 0, 0, .2)
  &mobile
    background-color: $ashes

</style>
