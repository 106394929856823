export default {
  methods: {
    formatNumber(number, decimals = 2) {
      const language = this.$store?.state?.authStore?.isAuthenticated
        ? this.$store.state.meStore.me.profile.preferred_language
        : 'nl';
      const numberFormat = new Intl.NumberFormat(language, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });

      let setMask = '';
      const formattedNumber = numberFormat
        .formatToParts(number)
        .map(({ type, value }) => {
          if (type === 'decimal') {
            setMask = `#${value}${'#'.repeat(decimals)}`;
          }
          return value;
        })
        .reduce((string, part) => string + part);

      return {
        value: decimals === 0 ? number : formattedNumber,
        mask: decimals === 0 ? '#' : setMask,
      };
    },

    // Reorganize the number to make it valid for the put/post request.
    formatValidRequestNumber(number) {
      return Number(number.replace(',', '.'));
    },
  },
};
