let activeSubscription = null;

function getActiveSubscription() {
  if (activeSubscription === null) {
    activeSubscription = window.location.host.split('.')[0];
  }

  return activeSubscription;
}

function decimalRound(value, decimals = 2) {
  const exp = 10 ** decimals;

  return Math.round((value + Number.EPSILON) * exp) / exp;
}

const cloneFailedTypesCache = [];

function clone(target) {
  if (!cloneFailedTypesCache.includes(typeof target) && 'structuredClone' in window) {
    try {
      return window.structuredClone(target);
    } catch {
      cloneFailedTypesCache.push(typeof target);

      return JSON.parse(JSON.stringify(target));
    }
  }

  return JSON.parse(JSON.stringify(target));
}

function ucfirst(value) {
  return value[0].toLocaleUpperCase() + value.slice(1);
}

export function useHelpers() {
  return {
    getActiveSubscription,
    decimalRound,
    clone,
    ucfirst,
  };
}

export default {
  methods: {
    getActiveSubscription,
    decimalRound,
    clone,
    ucfirst,
  },
};
