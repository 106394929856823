import { Notify } from 'quasar';
import { translate } from 'src/mixins/translator';

export default {
  UPDATE_ME(state, data) {
    state.me = data;
  },

  SET_USER_PREFERENCES(state, data) {
    state.userPreferences = data;
  },

  UPDATE_USER_PREFERENCE(state, { preference }) {
    const updatedPreferences = [...state.userPreferences];

    const preferenceIndex = updatedPreferences.findIndex(
      ({ id }) => id === preference.id,
    );

    updatedPreferences[preferenceIndex] = preference;

    state.userPreferences = updatedPreferences;
  },

  CLEAR_ME(state) {
    state.me = {};
    state.userPreferences = {};
  },

  SET_USER_PREFERENCES_MODULE(state, data) {
    const preferenceIndex = state.userPreferences.findIndex((module) => module.preferences.module === data.preferences.module);
    state.userPreferences[preferenceIndex] = data;
  },

  ADD_USER_PREFERENCES_MODULE(state, data) {
    state.userPreferences.push(data);
  },

  NEW_VERSION_AVAILABLE(state, version) {
    state.globalNotifications.newVersionAvailable?.();

    state.globalNotifications.newVersionAvailable = Notify.create({
      type: 'positive',
      color: 'green-12',
      textColor: 'black',
      icon: 'upgrade',
      message: translate(this.state.stringsStore.strings)('notifications.version.new_version_available', { version }),
      caption: translate(this.state.stringsStore.strings)('notifications.version.reload_page_for_new_version'),
      timeout: 0,
      position: 'center',
      actions: [
        {
          icon: 'refresh',
          color: 'black',
          rounded: true,
          handler: () => {
            location.reload();
          },
        },
      ],
    });
  },

  MAINTENANCE_MODE_ENABLED(state, secret) {
    state.globalNotifications.maintenanceModeEnabled = Notify.create({
      type: 'negative',
      icon: 'construction',
      message: translate(this.state.stringsStore.strings)('notifications.maintenance_mode.enabled'),
      caption: secret
        ? translate(this.state.stringsStore.strings)('notifications.maintenance_mode.secret', { secret })
        : undefined,
      timeout: 0,
      position: secret ? 'bottom' : 'center',
    });
  },

  MAINTENANCE_MODE_DISABLED(state) {
    state.globalNotifications.maintenanceModeEnabled?.();
  },
};
