// This file holds the general Regular expressions.
export default {
  data() {
    return {
      // Check if the time is correct between 00:01 - 23:59
      twentyFourHour: /\b([01][0-9]|2[0-3]):([0-5][0-9])\b/,

      // check if string matches military time between 0000 - 2359
      militaryTime: /\b([01][0-9]|2[0-3])([0-5][0-9])\b/,

      // Check if the email is set correctly.
      emailCheckRegex: /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/,
    };
  },
};
