import moment from 'moment';
import { useStore } from 'vuex';

function formatValidRequestDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function useFieldFormatters() {
  const store = useStore();
  const formatDate = store.state.meStore.me?.profile?.preferred_date_format?.toUpperCase() ?? 'YYYY-MM-DD';
  const formatTime = store.state.meStore.me?.profile?.preferred_time_format ?? 'HH:mm';

  return {
    formatTime(value, UTC) {
      const formattedTime = UTC
        ? moment.utc(value).format(formatTime)
        : moment(value).format(formatTime);

      return formattedTime;
    },

    formatDate(value) {
      if (value) {
        return moment(value).format(formatDate);
      }

      return value;
    },
    formatDateTime(value) {
      // Formatted as "2020-01-23 12:00" if no user settings.
      const formattedDateTime = `${moment(value).format(`${formatDate}`)} ${moment.utc(value).format(formatTime)}`;
      // return formatted date and time values
      return formattedDateTime;
    },

    formatValidRequestDate(date) {
      return formatValidRequestDate(date);
    },
  };
}

export default {
  data() {
    return {
      validFormatDate: this.$store.state?.meStore?.me?.profile?.preferred_date_format ?? 'YYYY-MM-DD',
      validFormatTime: this.$store.state?.meStore?.me?.profile?.preferred_time_format ?? 'HH:mm',
    };
  },

  methods: {
    formatTime(value, UTC) {
      const formattedTime = UTC
        ? moment.utc(value).format(this.validFormatTime)
        : moment(value).format(this.validFormatTime);

      return formattedTime;
    },

    formatDate(value) {
      if (value) {
        return moment(value).format(this.validFormatDate);
      }

      return value;
    },

    formatDateTime(value) {
      const formattedDateTime = `${moment(value).format(`${this.validFormatDate}`)} ${this.formatTime(value, true)}`;

      return formattedDateTime;
    },

    formatValidRequestDate(date) {
      return formatValidRequestDate(date);
    },

    formatValidRequestTime(value) {
      // check if user have time preferences stored
      const userTimePreference = !this.$store.state.meStore.me.profile.preferred_time_format || undefined;
      // valid time format for post/put requests
      const validFormat = 'HH:mm:ss';
      const validTimeFormat = moment(value, userTimePreference).format(validFormat);
      return validTimeFormat;
    },

    // second param ISO (boolean) if request is with T or not
    formatValidRequestDateTime(value, ISO) {
      // valid date and time format for post/put requests
      const validFormat = `YYYY-MM-DD${ISO ? 'T' : ' '}HH:mm:ss${ISO ? 'Z' : ''}`;
      const validDateTimeFormat = moment(value, ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm:ss']).format(validFormat);
      return validDateTimeFormat;
    },
  },
};
