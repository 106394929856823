export default async ({ store }) => {
  // if response data, set colors from database
  if (store.state.subscriptionStore.subscriptionLoaded) {
    document.body.style.setProperty('--q-primary', store.state.subscriptionStore.subscription.ui.primary);
    document.body.style.setProperty('--q-secondary', store.state.subscriptionStore.subscription.ui.secondary);
    document.body.style.setProperty('--q-tertiary', store.state.subscriptionStore.subscription.ui.tertiary);
    document.body.style.setProperty('--q-background', store.state.subscriptionStore.subscription.ui.background);
  } else {
    // if no response data, set default colors from Climatools
    document.body.style.setProperty('--q-primary', '#21B9D8');
    document.body.style.setProperty('--q-secondary', '#323A45');
    document.body.style.setProperty('--q-tertiary', '#21B9D8');
    document.body.style.setProperty('--q-background', '#323A45');
  }
};
