/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/nl'



import {QBadge,QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QSeparator,QExpansionItem,QAvatar,QBtnDropdown,QMenu,QCard,QCardSection,QCardActions,QInput,QSelect,QForm,QField,QRadio,QCheckbox,QToggle,QBtnToggle,QOptionGroup,QSlider,QRange,QTime,QDate,QScrollArea,QUploader,QDialog,QPopupProxy,QInnerLoading,QSpinner,QMarkupTable,QTabPanels,QTabs,QTab,QRouteTab,QTabPanel,QPageSticky,QSpace,QScrollObserver,QSlideTransition,QBar,QChip,QCircularProgress,QTooltip,QImg,Ripple,ClosePopup,Scroll,ScrollFire,Notify,Cookies,Loading,Dialog,AppFullscreen} from 'quasar'



export default { config: {},lang,components: {QBadge,QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QSeparator,QExpansionItem,QAvatar,QBtnDropdown,QMenu,QCard,QCardSection,QCardActions,QInput,QSelect,QForm,QField,QRadio,QCheckbox,QToggle,QBtnToggle,QOptionGroup,QSlider,QRange,QTime,QDate,QScrollArea,QUploader,QDialog,QPopupProxy,QInnerLoading,QSpinner,QMarkupTable,QTabPanels,QTabs,QTab,QRouteTab,QTabPanel,QPageSticky,QSpace,QScrollObserver,QSlideTransition,QBar,QChip,QCircularProgress,QTooltip,QImg},directives: {Ripple,ClosePopup,Scroll,ScrollFire},plugins: {Notify,Cookies,Loading,Dialog,AppFullscreen} }

