/**
 * RequestChecker is used to check if a value has been saved initially during post request
 * within the indexedDB. based on that check if we need the locally saved data or the data
 * we received from the api.
 */

import localforage from 'localforage';
import _ from 'lodash';

export default {
  data() {
    return {
      localforageInstanceRequests: localforage.createInstance({ name: 'saved_requests_db' }),
    };
  },

  methods: {
    /**
      *  When getting request check first if the get request was saved locally to the IndexDB.
      *
      * @param endpointData value to the endpoint.
      * @param indexedDbPath path key for the endpoint that was saved during creating
      * @param listType check if we are checking a list or a detail list.
      */
    checkRequest(endpointData, indexedDbPath, listType) {
      return new Promise((resolve, reject) => {
        try {
          this.localforageInstanceRequests.getItem(indexedDbPath).then((indexedDbRes) => {
            // If indexedDB data is not found simply return the api request data back.
            if (!indexedDbRes) {
              resolve(endpointData);
            } else if (listType === 'item') {
              // Correct key found for this item.
              // We have a indexedDB value use this to compare the api data.
              endpointData.then((endpointRes) => {
                // If the data from the api has no value simply use the value within the indexedDB.
                if (endpointRes.data.data.length === 0) {
                  const indexedDbData = { data: { data: indexedDbRes } };
                  resolve(indexedDbData);
                } else {
                  // If there is a value simply remove the local data and use the api endpoint request.
                  resolve(endpointData);
                  this.localforageInstanceRequests.removeItem(indexedDbPath);
                }
              });
            } else if (listType === 'overview') {
              // Check if during creating a list item of the specific item is found within the endpoint.
              if (endpointData.find(x => x.id === indexedDbRes.data.id)) {
                // Data found, remove locally saved data and proceed with the current endpoint data.
                this.localforageInstanceRequests.removeItem(indexedDbPath);
                resolve(endpointData);
              } else {
                const addLocalDataToList = endpointData.data.data;
                addLocalDataToList.unshift(indexedDbRes.data);
                // Data is not found, use the data from the indexedDB.
                resolve({ data: { data: addLocalDataToList } });
              }
            } else if (listType === 'list') {
              endpointData.then((res) => {
                const compareVal = _.differenceWith(indexedDbRes, res.data.data, (o1, o2) => o1.id === o2.id);
                let listData = [];

                if (compareVal.length > 0) {
                  // First remove and then add the changed value.
                  this.localforageInstanceRequests.removeItem(indexedDbPath).then(() => {
                    // Set the remaining array values back to the local database
                    this.localforageInstanceRequests.setItem(indexedDbPath, compareVal);
                  });

                  listData = compareVal;
                  if (res.length > 0) {
                    res.forEach(element => {
                      listData.push(element);
                    });
                  }

                  resolve({ data: { data: listData } });
                } else {
                  resolve(endpointData);
                  this.localforageInstanceRequests.removeItem(indexedDbPath);
                }
              });
            }
          }).catch((error) => {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
