import { boot } from 'quasar/wrappers';

// Mixins that are registered for global use within the app.
import nameInitials from 'src/mixins/nameInitials';
import getStrings from '../mixins/getStrings';
import translator from '../mixins/translator';
import filtersMixin from '../components/overview/functions/filtersMixin.js';
import fieldFormatter from '../mixins/table/fieldFormatters';
import checkDevice from '../mixins/device/checkDevice';
import regex from '../mixins/regex/regex.js';
import errorHandle from '../mixins/errorHandle';
import moduleRights from '../mixins/moduleRights';
import defaultLoader from '../mixins/loader/defaultLoader';
import preferredNumberFormat from '../mixins/input/preferredNumberFormat.js';
import serviceWorkerValues from '../service-worker/serviceWorkerValues';
import requestChecker from '../mixins/requestChecker';
import subscriptionPackageChecker from '../mixins/subscriptions/subscriptionPackageChecker.js';
import notifications from '../mixins/notifications';
import helpers from '../mixins/helpers';

export default boot(({ app }) => {
  app.mixin(getStrings);
  app.mixin(filtersMixin);
  app.mixin(fieldFormatter);
  app.mixin(checkDevice);
  app.mixin(regex);
  app.mixin(errorHandle);
  app.mixin(moduleRights);
  app.mixin(defaultLoader);
  app.mixin(nameInitials);
  app.mixin(preferredNumberFormat);
  app.mixin(serviceWorkerValues);
  app.mixin(requestChecker);
  app.mixin(subscriptionPackageChecker);
  app.mixin(notifications);
  app.mixin(translator);
  app.mixin(helpers);
});
