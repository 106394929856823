import qs from 'qs';
import { axiosInstance, baseApiAxiosInstance } from '../../boot/axios';
import EventBus from '../../event-bus';

export default {
  authLogin({ commit }, user) {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        // Return promise values back to component
        const response = await axiosInstance.post(
          'login',
          qs.stringify({
            email: user.email,
            password: user.password,
            device: user.device,
          }),
        );
        // set token in the header
        axiosInstance.defaults.headers.common.Authorization = `${response.data.data.token.type} ${response.data.data.token.token}`;
        baseApiAxiosInstance.defaults.headers.common.Authorization = `${response.data.data.token.type} ${response.data.data.token.token}`;
        window.Echo.options.auth.headers.Authorization = `${response.data.data.token.type} ${response.data.data.token.token}`;

        // Succeeded -> mutate state
        commit('LOGIN_SUCCESS', response.data.data);
        // resolve
        resolve();
      } catch (error) {
        // Logout action and reject.
        console.warn(error);
        reject(error);
      }
    });
  },

  authLogout: (
    { commit, dispatch },
    { fromUnauthorizedRequest = false, router = null } = {},
  ) => {
    if (fromUnauthorizedRequest) {
      return Promise.resolve(commit('LOGOUT'));
    }

    return axiosInstance
      .post('logout')
      .then(async () => {
        const handleAfterNavigation = () => {
          // Finally, clear user data
          dispatch('clearMe');
          // Clean up all root event listeners on log out.
          EventBus.$off();
        };

        // Set isAuthenticated state to false
        commit('LOGOUT');

        await router?.push({ name: 'login' });

        return handleAfterNavigation;
      })
      .catch((error) => {
        // Log action and status.
        console.warn(error);

        // no-op
        return () => {
          //
        };
      });
  },
};
