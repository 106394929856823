const routes = [
  {
    path: '/',
    component: () => import('../layouts/OutsideLayout.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'login',
        },
      },
      {
        path: 'maintenance',
        name: 'maintenance',
        component: () => import('../pages/Maintenance.vue'),
      },
      {
        path: 'login',
        name: 'login',
        meta: {
          group: 'auth',
          title: 'login.input.button',
        },
        component: () => import('../modules/auth/Auth.vue'),
      },
      {
        path: 'reset',
        name: 'reset_request',
        meta: {
          group: 'auth',
          title: 'action.reset',
        },
        component: () => import('../modules/auth/Auth.vue'),
      },
      {
        path: 'reset/:email/:code',
        name: 'reset_response',
        meta: {
          group: 'auth',
          title: 'action.reset',
        },
        component: () => import('../modules/auth/Auth.vue'),
      },
      {
        path: 'accept/:email/:code',
        name: 'accept',
        meta: {
          group: 'auth',
          title: 'login.input.button',
        },
        component: () => import('../modules/auth/Auth.vue'),
      },
      {
        path: 'inactive',
        name: 'inactive',
        meta: {
          title: 'subscription.expired.title',
        },
        component: () => import('../modules/auth/Auth.vue'),
      },
      {
        path: 'card/installation/:code',
        name: 'installations_card',
        meta: {
          title: 'module.name.installations',
          action: 'details',
          menu: 'module.name.installations',
        },
        component: () => import('../modules/installations/card/InstallationsCard.vue'),
      },
      {
        path: 'signed/installations/:id(\\d+)',
        name: 'signed_installation_detail',
        meta: {
          title: 'module.name.installations',
        },
        component: () => import('../modules/installations/Installation.vue'),
      },
      {
        path: 'card/cylinder/:code',
        name: 'cylinders_card',
        meta: {
          title: 'module.name.cylinders',
          action: 'details',
          menu: 'module.category.refrigerants',
          submenu: 'module.name.cylinders',
        },
        component: () => import('../modules/refrigerants/cylinders/card/CylindersCard.vue'),
      },
      {
        path: 'card/not-found',
        name: 'error_card',
        meta: {
          title: 'card.error.notfound.title',
        },
        component: () => import('../pages/NotFound.vue'),
      },
    ],
  },
  {
    path: '/homepage',
    alias: '/',
    redirect: { name: 'dashboard' },
    component: () => import('../layouts/MyLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'dashboard' },
      },
      {
        path: '/profile',
        name: 'profile',
        meta: {
          title: 'module.name.profile',
          menu: 'module.name.profile',
        },
        component: () => import('../modules/me/Profile.vue'),
      },
      {
        path: '/settings',
        name: 'settings',
        meta: {
          title: 'module.name.settings',
          menu: 'module.name.settings',
        },
        component: () => import('../modules/me/settings/Details.vue'),
      },
      {
        path: '/company-profile',
        name: 'company_profile',
        meta: {
          title: 'module.name.companies',
          menu: 'module.name.profile',
        },
        component: () => import('../modules/me/company-profile/CompanyProfile.vue'),
      },
      {
        path: '/integration',
        name: 'integrations',
        meta: {
          title: 'module.name.integrations',
          menu: 'module.name.integrations',
        },
        component: () => import('../modules/integrations/overview/IntegrationsOverview.vue'),
      },
      {
        path: '/integration/:id',
        name: 'integrations_detail',
        meta: {
          title: 'module.name.integrations',
          menu: 'module.name.integrations',
          // submenu: 'module.name.users',
          // permission: 'users',
        },
        component: () => import('../modules/integrations/detail/IntegrationsDetail.vue'),
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          title: 'module.name.dashboard',
          menu: 'module.name.dashboard',
        },
        component: () => import('../modules/dashboard/Dashboard.vue'),
      },
      {
        path: '/users',
        name: 'users',
        meta: {
          title: 'module.name.users',
          menu: 'module.category.users_user_groups',
          submenu: 'module.name.users',
          permission: 'users',
        },
        component: () => import('../modules/users/overview/UsersOverview.vue'),
      },
      {
        path: '/users/invite',
        name: 'users_invite',
        meta: {
          title: 'module.name.users',
          action: 'invite',
          menu: 'module.category.users_user_groups',
          submenu: 'module.name.users',
          permission: 'users.add',
        },
        component: () => import('../modules/users/invite/UsersInvite.vue'),
      },
      {
        path: '/users/:id(\\d+)',
        name: 'users_detail',
        meta: {
          title: 'module.name.users',
          action: 'edit',
          menu: 'module.category.users_user_groups',
          submenu: 'module.name.users',
          permission: 'users',
        },
        component: () => import('../modules/users/detail/UsersDetail.vue'),
      },
      {
        path: '/user-groups',
        name: 'user-groups',
        meta: {
          title: 'module.name.user_groups',
          menu: 'module.category.users_user_groups',
          submenu: 'module.name.user_groups',
          permission: 'user_groups',
        },
        component: () => import('../modules/usergroups/overview/UserGroupsOverview.vue'),
      },
      {
        path: '/user-groups/add',
        name: 'user-groups-add',
        meta: {
          title: 'module.name.user_groups',
          action: 'add',
          menu: 'module.category.users_user_groups',
          submenu: 'module.name.user_groups',
          permission: 'user_groups.add',
        },
        component: () => import('../modules/usergroups/add/UserGroupsAdd.vue'),
      },
      {
        path: '/user-groups/:id(\\d+)',
        name: 'user-groups-detail',
        meta: {
          title: 'module.name.user_groups',
          action: 'edit',
          menu: 'module.category.users_user_groups',
          submenu: 'module.name.user_groups',
          permission: 'user_groups',
        },
        component: () => import('../modules/usergroups/detail/UserGroupsDetail.vue'),
      },
      {
        path: '/relations',
        name: 'relations',
        meta: {
          title: 'module.name.relations',
          menu: 'module.category.crm',
          submenu: 'module.name.relations',
          permission: 'relations',
        },
        component: () => import('../modules/crm/relations/overview/RelationsOverview.vue'),
      },
      {
        path: '/relations/add',
        name: 'relations_add',
        meta: {
          title: 'module.name.relations',
          action: 'add',
          menu: 'module.category.crm',
          submenu: 'module.name.relations',
          permission: 'relations.add',
        },
        component: () => import('../modules/crm/relations/add/RelationsAdd.vue'),
      },
      {
        path: '/relations/:id(\\d+)',
        name: 'relations_detail',
        meta: {
          title: 'module.name.relations',
          action: 'edit',
          menu: 'module.category.crm',
          submenu: 'module.name.relations',
          permission: 'relations',
        },
        component: () => import('../modules/crm/relations/detail/RelationsDetail.vue'),
      },
      {
        path: '/companies',
        name: 'companies',
        meta: {
          title: 'module.name.companies',
          menu: 'module.category.crm',
          submenu: 'module.name.companies',
          permission: 'companies',
        },
        component: () => import('../modules/crm/companies/overview/CompaniesOverview.vue'),
      },
      {
        path: '/companies/add',
        name: 'companies_add',
        meta: {
          title: 'module.name.companies',
          action: 'add',
          menu: 'module.category.crm',
          submenu: 'module.name.companies',
          permission: 'companies.add',
        },
        component: () => import('../modules/crm/companies/add/CompaniesAdd.vue'),
      },
      {
        path: '/companies/:id(\\d+)',
        name: 'companies_detail',
        meta: {
          title: 'module.name.companies',
          action: 'edit',
          menu: 'module.category.crm',
          submenu: 'module.name.companies',
          permission: 'companies',
        },
        component: () => import('../modules/crm/companies/detail/CompaniesDetail.vue'),
      },
      {
        path: '/installations',
        name: 'installations',
        meta: {
          title: 'module.name.installations',
          menu: 'module.name.installations',
          permission: 'installations',
        },
        component: () => import('../modules/installations/overview/Overview.vue'),
      },
      {
        path: '/installations/add/:genre([\\w|-]+)',
        name: 'installations_add',
        meta: {
          title: 'module.name.installations',
          action: 'add',
          menu: 'module.name.installations',
          permission: 'installations.add',
        },
        component: () => import('../modules/installations/Installation.vue'),
      },
      {
        path: '/installations/:id(\\d+)',
        name: 'installations_detail',
        meta: {
          title: 'module.name.installations',
          action: 'edit',
          menu: 'module.name.installations',
          permission: 'installations',
        },
        component: () => import('../modules/installations/Installation.vue'),
      },
      {
        path: '/installations/:id(\\d+)/copy',
        name: 'installations_copy',
        meta: {
          title: 'module.name.installations',
          action: 'add',
          menu: 'module.name.installations',
          permission: 'installations.add',
        },
        component: () => import('../modules/installations/Installation.vue'),
      },
      {
        path: '/cylinders',
        name: 'cylinders',
        meta: {
          title: 'module.name.cylinders',
          menu: 'module.category.refrigerants',
          submenu: 'module.name.cylinders',
          permission: 'cylinders',
        },
        component: () => import(
          '../modules/refrigerants/cylinders/overview/CylindersOverview.vue'
        ),
      },
      {
        path: '/cylinders/add',
        name: 'cylinders_add',
        meta: {
          title: 'module.name.cylinders',
          action: 'add',
          menu: 'module.category.refrigerants',
          submenu: 'module.name.cylinders',
          permission: 'cylinders.add',
        },
        component: () => import('../modules/refrigerants/cylinders/add/CylindersAdd.vue'),
      },
      {
        path: '/cylinders/:id(\\d+)',
        name: 'cylinders_detail',
        meta: {
          title: 'module.name.cylinders',
          action: 'edit',
          menu: 'module.category.refrigerants',
          submenu: 'module.name.cylinders',
          permission: 'cylinders',
        },
        component: () => import('../modules/refrigerants/cylinders/detail/CylindersDetail.vue'),
      },
      {
        path: '/mutations/:mutation?',
        name: 'mutations',
        meta: {
          title: 'module.name.mutations',
          menu: 'module.category.refrigerants',
          submenu: 'module.name.mutations',
          permission: 'mutations',
        },
        component: () => import('../modules/refrigerants/mutations/Mutations.vue'),
      },
      {
        path: '/allocations',
        name: 'allocations',
        meta: {
          title: 'module.name.allocations',
          menu: 'module.name.allocations',
          permission: 'allocations',
        },
        component: () => import('../modules/allocation/Allocation.vue'),
      },
      {
        path: '/assignments',
        name: 'assignments',
        meta: {
          title: 'module.name.assignments',
          menu: 'module.name.assignments',
          permission: 'assignments',
        },
        component: () => import('../modules/assignments/overview/Overview.vue'),
      },
      {
        path: '/assignments/:id(\\d+)',
        name: 'assignments_detail',
        meta: {
          title: 'module.name.assignments',
          action: 'edit',
          menu: 'module.name.assignments',
          permission: 'assignments',
        },
        component: () => import('../modules/assignments/detail/AssignmentsDetail.vue'),
      },
      {
        path: '/refrigerant-reports',
        name: 'refrigerant-reports',
        meta: {
          title: 'module.name.refrigerant_reports',
          menu: 'module.category.reports',
          submenu: 'module.name.refrigerant_reports',
          permission: 'refrigerant_reports',
        },
        component: () => import('../modules/reports/Refrigerants.vue'),
      },
      {
        path: '/installation-reports',
        name: 'installation-reports',
        meta: {
          title: 'module.name.installation_reports',
          menu: 'module.category.reports',
          submenu: 'module.name.installation_reports',
          permission: 'installation_reports',
        },
        component: () => import('../modules/reports/Installations.vue'),
      },
      {
        path: '/experience-reports',
        name: 'experience-reports',
        meta: {
          title: 'module.name.experience_reports',
          menu: 'module.category.reports',
          submenu: 'module.name.experience_reports',
          permission: 'experience_reports',
        },
        component: () => import('../modules/reports/Experience/Page.vue'),
      },
      {
        path: '/equipment',
        name: 'equipment',
        meta: {
          title: 'module.name.equipment',
          menu: 'module.name.equipment',
          permission: 'equipment',
        },
        component: () => import('../modules/equipment/overview/EquipmentOverview.vue'),
      },
      {
        path: '/equipment/add',
        name: 'equipment_add',
        meta: {
          title: 'module.name.equipment',
          action: 'add',
          menu: 'module.name.equipment',
          permission: 'equipment.add',
        },
        component: () => import('../modules/equipment/detail/EquipmentDetail.vue'),
      },
      {
        path: '/equipment/:id(\\d+)',
        name: 'equipment_detail',
        meta: {
          title: 'module.name.equipment',
          action: 'edit',
          menu: 'module.name.equipment',
          permission: 'equipment',
        },
        component: () => import('../modules/equipment/detail/EquipmentDetail.vue'),
      },
      {
        path: '/planning',
        name: 'planning',
        meta: {
          title: 'module.name.planning',
          menu: 'module.name.planning',
          permission: 'planning',
        },
        component: () => import('../modules/planning/PlanningWrapper.vue'),
        children: [
          {
            path: '/scheduler',
            name: 'scheduler',
            meta: {
              title: 'module.name.planning',
              menu: 'module.name.planning',
              permission: 'planning',
            },
            component: () => import('../modules/planning/scheduler/Scheduler.vue'),
          },
          {
            path: '/agenda',
            name: 'agenda',
            meta: {
              title: 'module.name.planning',
              menu: 'module.name.planning',
              permission: 'planning',
            },
            component: () => import('../modules/planning/agenda/Agenda.vue'),
          },
        ],
      },
      {
        path: '/checklists',
        name: 'checklists',
        meta: {
          title: 'module.name.checklists',
          menu: 'module.name.checklists',
          permission: 'checklists',
        },
        component: () => import('../modules/checklist/overview/ChecklistOverview.vue'),
      },
      {
        path: '/checklists/add',
        name: 'checklists_add',
        meta: {
          title: 'module.name.checklists',
          action: 'add',
          menu: 'module.name.checklists',
          permission: 'checklists.add',
        },
        component: () => import('../modules/checklist/detail/ChecklistDetail.vue'),
      },
      {
        path: '/checklists/:id(\\d+)',
        name: 'checklists_detail',
        meta: {
          title: 'module.name.checklists',
          action: 'edit',
          menu: 'module.name.checklists',
          permission: 'checklists.edit',
        },
        component: () => import('../modules/checklist/detail/ChecklistDetail.vue'),
      },
      {
        path: '/stocks',
        name: 'stocks',
        meta: {
          title: 'module.name.stocks',
          menu: 'module.category.refrigerants',
          submenu: 'module.name.stocks',
          permission: 'stocks.view',
        },
        component: () => import('../modules/refrigerants/stock/Stock.vue'),
      },
      {
        path: '/maintenance-contracts',
        name: 'maintenance-contracts',
        meta: {
          title: 'module.name.maintenance_contracts',
          menu: 'module.name.maintenance_contracts',
          permission: 'maintenance_contracts.view',
        },
        component: () => import('../modules/maintenance-contracts/overview/Overview.vue'),
      },
      {
        path: '/maintenance-contracts/add',
        name: 'maintenance-contracts_add',
        meta: {
          title: 'module.name.maintenance_contracts',
          action: 'add',
          menu: 'module.name.maintenance_contracts',
          permission: 'maintenance_contracts.add',
        },
        component: () => import('../modules/maintenance-contracts/create/Create.vue'),
      },
      {
        path: '/maintenance-contracts/:id(\\d+)',
        name: 'maintenance-contracts_detail',
        meta: {
          title: 'module.name.maintenance_contracts',
          action: 'edit',
          menu: 'module.name.maintenance_contracts',
          permission: 'maintenance_contracts.edit',
        },
        component: () => import('../modules/maintenance-contracts/detail/Detail.vue'),
      },
      {
        path: '/incidents',
        name: 'incidents',
        meta: {
          title: 'module.name.incidents',
          menu: 'module.name.incidents',
          permission: 'incidents.view',
        },
        component: () => import('src/modules/incidents/overview/Details.vue'),
      },
      {
        path: '/incidents/add',
        name: 'incidents_add',
        meta: {
          title: 'module.name.incidents',
          action: 'add',
          menu: 'module.name.incidents',
          permission: 'incidents.add',
        },
        component: () => import('src/modules/incidents/incident/Details.vue'),
      },
      {
        path: '/incidents/:id(\\d+)',
        name: 'incidents_detail',
        meta: {
          title: 'module.name.incidents',
          action: 'edit',
          menu: 'module.name.incidents',
          permission: 'incidents',
        },
        component: () => import('src/modules/incidents/incident/Details.vue'),
      },
    ],
  },
];

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '/:catchAll(.*)*',
    component: () => import('src/pages/Error404.vue'),
  });
}

export default routes;
