import { store } from 'quasar/wrappers';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Import the module
import apiStore from './api';
import authStore from './auth';
import stringsStore from './strings';
import navigationStore from './navigation';
import meStore from './me';
import filtersStore from './filters';
import mutationStore from './mutations';
import subscriptionStore from './subscription';
import connectionStore from './connection';
import assignmentsStore from './assignments';
import temporarySignedStore from './temporary_signed';
import settingStore from './settings';

export default store((/* { ssrContext } */) => {
  const Store = createStore({
    plugins: [createPersistedState()],
    modules: {
      // Reference the modules
      apiStore,
      authStore,
      stringsStore,
      navigationStore,
      meStore,
      filtersStore,
      mutationStore,
      subscriptionStore,
      connectionStore,
      assignmentsStore,
      temporarySignedStore,
      settingStore,
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING,
  });

  return Store;
});
