// import something here
import { Platform, Notify } from 'quasar';

export default async (/* { Vue } */) => {
  if (Platform.is.ie) {
    Notify.create({
      message:
        'Your browser is not supported. To use Climatools, we recomend using the last version of Edge or any other browser.',
      icon: 'sentiment_very_dissatisfied',
      position: 'top',
      timeout: 0,
    });
  }
  if (Platform.is.edge && Platform.is.versionNumber < 73) {
    Notify.create({
      message:
        'Your browser is not supported. To use Climatools, we recomend using the last version of Edge or any other browser.',
      icon: 'sentiment_very_dissatisfied',
      position: 'top',
      timeout: 0,
    });
  }
};
