const sortOptionByLabel = (a, b) => a.label.localeCompare(b.label);

export default {
  ADD_CHECKLIST_CATEGORY(state, { checklist, assignment, installation }) {
    state.checklists[assignment] ??= {};
    state.checklists[assignment][installation] ??= [];
    state.checklists[assignment][installation] = [
      ...state.checklists[assignment][installation],
      checklist,
    ];
  },
  ADD_ROW_TO_GROUPED_FIELDS(state, { assignment, installation, checklist, category, itemIndex, group }) {
    /** @todo immutability */
    const fieldsInGroup = state
      .checklistFields
      ?.[assignment.id]
      ?.[installation.id]
      ?.[checklist.id]
      ?.[category.id]
      ?.[itemIndex]
      ?.data.fields ?? [];

    const row = {
      id: crypto.randomUUID(),
      values: fieldsInGroup.reduce((values, field) => {
        switch (field.repeat_type) {
          case 'once':
            return {
              ...values,
              [field.name]: null,
            };

          case 'per_circuit':
            return {
              ...values,
              [field.name]: new Array(installation.refrigerant_circuits.length).fill(null),
            };

          case 'per_operation_mode':
            return {
              ...values,
              [field.name]: new Array(installation.genre_data?.operation_modes ?? 0).fill(null),
            };

          default:
            return values;
        }
      }, {}),
    };

    state
      .checklistValues
      ?.[assignment.id]
      ?.[installation.id]
      ?.[checklist.id]
      ?.[category.id]
      ?.[group.name]
      ?.push(row);

    ++state.checklistFields[assignment.id][installation.id][checklist.id][category.id][itemIndex].data.rows;

    const categoryIndex = state
      ?.checklistCategories
      ?.[assignment.id]
      ?.[installation.id]
      ?.[checklist.id]
      ?.findIndex(({ id }) => id === category.id);

    if (categoryIndex !== null) {
      state.checklistCategories[assignment.id][installation.id][checklist.id][categoryIndex].is_dirty = true;
    }
  },
  APPEND_OPTION_TO_FIELD(state, { assignment, installation, checklist, category, group, itemIndex, rowIndex, newOption }) {
    if (group && rowIndex !== -1) {
      state.checklistFields[assignment][installation][checklist][category][rowIndex].data.fields[itemIndex].options = [
        ...(state.checklistFields[assignment][installation][checklist][category][rowIndex].data.fields[itemIndex].options ?? []),
        newOption,
      ];

      state
        .checklistFields[assignment][installation][checklist][category][rowIndex]
        .data.fields[itemIndex]
        .options
        .sort(sortOptionByLabel);
    } else {
      state.checklistFields[assignment][installation][checklist][category][itemIndex].data.options = [
        ...(state.checklistFields[assignment][installation][checklist][category][itemIndex].data.options ?? []),
        newOption,
      ];

      state
        .checklistFields[assignment][installation][checklist][category][itemIndex]
        .data
        .options
        .sort(sortOptionByLabel);
    }
  },
  CLEAR_ASSIGNMENTS(state) {
    state.checklists = {};
    state.checklistFields = {};
    state.checklistValues = {};
    state.checklistCategories = {};
  },
  REMOVE_ROW_FROM_GROUPED_FIELDS(state, { assignment, installation, checklist, category, itemIndex, group, rowIndex }) {
    /** @todo immutability */
    state
      .checklistValues
      ?.[assignment.id]
      ?.[installation.id]
      ?.[checklist.id]
      ?.[category.id]
      ?.[group.name]
      ?.splice(rowIndex, 1);

    --state.checklistFields[assignment.id][installation.id][checklist.id][category.id][itemIndex].data.rows;

    const categoryIndex = state
      ?.checklistCategories
      ?.[assignment.id]
      ?.[installation.id]
      ?.[checklist.id]
      ?.findIndex(({ id }) => id === category.id);

    if (categoryIndex !== null) {
      state.checklistCategories[assignment.id][installation.id][checklist.id][categoryIndex].is_dirty = true;
    }
  },
  SET_CHECKLISTS(state, { checklists, assignment, installation }) {
    state.checklists[assignment] ??= {};
    state.checklists[assignment][installation] = checklists;
  },
  SET_CHECKLIST_CATEGORIES(state, { categories, assignment, installation, checklist }) {
    state.checklistCategories[assignment] ??= {};
    state.checklistCategories[assignment][installation] ??= {};
    state.checklistCategories[assignment][installation][checklist] = categories.map((category) => ({
      ...category,
      is_dirty: false,
    }));
  },
  SET_CHECKLIST_CATEGORY_FIELDS(state, { fields, assignment, installation, checklist, category }) {
    state.checklistFields[assignment] ??= {};
    state.checklistFields[assignment][installation] ??= {};
    state.checklistFields[assignment][installation][checklist] ??= {};
    state.checklistFields[assignment][installation][checklist][category] = fields;
  },
  SET_CHECKLIST_CATEGORY_VALUES(state, { values, assignment, installation, checklist, category }) {
    state.checklistValues[assignment] ??= {};
    state.checklistValues[assignment][installation] ??= {};
    state.checklistValues[assignment][installation][checklist] ??= {};
    state.checklistValues[assignment][installation][checklist][category] = values;
  },
  SET_CALCULATED_CHECKLIST_FIELDS(state, { values, assignment, installation, checklist }) {
    Object.entries(values).forEach(({ 0: category, 1: items }) => {
      Object.entries(items).forEach(({ 0: itemName, 1: item }) => {
        const itemIsGroup = state
          .checklistFields[assignment][installation][checklist][category]
          .findIndex(({ type, data }) => type === 'group' && data.name === itemName) !== -1;

        if (itemIsGroup) {
          item.forEach((row) => {
            const rowIndexById = state
              .checklistValues[assignment][installation][checklist][category][itemName]
              .findIndex(({ id }) => id === row.id);

            if (rowIndexById !== -1) {
              state.checklistValues[assignment][installation][checklist][category][itemName][rowIndexById].values = {
                ...state.checklistValues[assignment][installation][checklist][category][itemName][rowIndexById].values,
                ...row.values,
              };
            }
          });
        } else {
          state.checklistValues[assignment][installation][checklist][category][itemName].value = item;
        }
      });
    });
  },
  SET_CALCULATED_CHECKLIST_CATEGORY_FIELDS(state, { values, assignment, installation, checklist, category }) {
    Object.entries(values).forEach(({ 0: itemName, 1: item }) => {
      const itemIsGroup = state
        .checklistFields[assignment][installation][checklist][category]
        .findIndex(({ type, data }) => type === 'group' && data.name === itemName) !== -1;

      if (itemIsGroup) {
        item.forEach((row) => {
          const rowIndexById = state
            .checklistValues[assignment][installation][checklist][category][itemName]
            .findIndex(({ id }) => id === row.id);

          if (rowIndexById !== -1) {
            state.checklistValues[assignment][installation][checklist][category][itemName][rowIndexById].values = {
              ...state.checklistValues[assignment][installation][checklist][category][itemName][rowIndexById].values,
              ...row.values,
            };
          }
        });
      } else {
        state.checklistValues[assignment][installation][checklist][category][itemName].value = item;
      }
    });
  },
  UPDATE_CATEGORY_FIELD_VALUE(state, { assignment, installation, checklist, category, group, rowIndex, field, value }) {
    if (group && rowIndex !== -1) {
      state.checklistValues[assignment][installation][checklist][category.id][group][rowIndex].values[field.name];
      state.checklistValues[assignment][installation][checklist][category.id][group][rowIndex].values[field.name] = value;
    } else {
      state.checklistValues[assignment] ??= {};
      state.checklistValues[assignment][installation] ??= {};
      state.checklistValues[assignment][installation][checklist] ??= {};
      state.checklistValues[assignment][installation][checklist][category.id] ??= {};
      state.checklistValues[assignment][installation][checklist][category.id][field.name] = value;
    }

    const categoryIndex = state
      .checklistCategories[assignment][installation][checklist]
      .findIndex(({ id }) => id === category.id);

    state.checklistCategories[assignment][installation][checklist][categoryIndex].is_dirty = true;
  },
  UPDATE_CATEGORY_FIELD_COMMENTS(state, { assignment, installation, checklist, category, group, rowIndex, field, comments }) {
    if (group && rowIndex !== -1) {
      state.checklistValues[assignment][installation][checklist][category.id][group][rowIndex].values[field.name] ??= { value: null, comments: null };
      state.checklistValues[assignment][installation][checklist][category.id][group][rowIndex].values[field.name].comments = comments;
    } else {
      state.checklistValues[assignment] ??= {};
      state.checklistValues[assignment][installation] ??= {};
      state.checklistValues[assignment][installation][checklist] ??= {};
      state.checklistValues[assignment][installation][checklist][category.id] ??= {};
      state.checklistValues[assignment][installation][checklist][category.id][field.name].comments = comments;
    }

    const categoryIndex = state
      .checklistCategories[assignment][installation][checklist]
      .findIndex(({ id }) => id === category.id);

    state.checklistCategories[assignment][installation][checklist][categoryIndex].is_dirty = true;
  },
  UPDATE_CATEGORY_FIELD_META(state, { assignment, installation, checklist, category, group, rowIndex, field, meta }) {
    if (group && rowIndex !== -1) {
      state.checklistValues[assignment][installation][checklist][category.id][group][rowIndex].values[field.name] ??= { value: null, meta: null };
      state.checklistValues[assignment][installation][checklist][category.id][group][rowIndex].values[field.name].meta = meta;
    } else {
      state.checklistValues[assignment] ??= {};
      state.checklistValues[assignment][installation] ??= {};
      state.checklistValues[assignment][installation][checklist] ??= {};
      state.checklistValues[assignment][installation][checklist][category.id] ??= {};
      state.checklistValues[assignment][installation][checklist][category.id][field.name].meta = meta;
    }

    const categoryIndex = state
      .checklistCategories[assignment][installation][checklist]
      .findIndex(({ id }) => id === category.id);

    state.checklistCategories[assignment][installation][checklist][categoryIndex].is_dirty = true;
  },
  MARK_CATEGORY_AS_CLEAN(state, { assignment, installation, checklist, category }) {
    const categoryIndex = state
      .checklistCategories[assignment][installation][checklist]
      .findIndex(({ id }) => id === category);

    state.checklistCategories[assignment][installation][checklist][categoryIndex].is_dirty = false;
  },
};
